import React, {Component} from 'react';
import {Link} from "react-router-dom"
import Jumbotron from '../../../Components/Jumbotron/jumbotron';
import Biyografi from "../../../Components/Biyografi/biyografi";
import {bindActionCreators} from "redux";
import {getKitaplarAction} from "../../../Redux/Actions/kitapActions";
import {connect} from "react-redux";
import {getSayfaAction} from "../../../Redux/Actions/sayfaAction";
import Search from "../../../Components/SearchButton/search"
import SearchDetayli from "../../arama/arama"
import Icon from "../../../Components/Iconlar"
import ReactHtmlParser from "react-html-parser";
import Truncate from 'react-truncate';
import ReactPaginate from 'react-paginate';
import Autosuggest from 'react-autosuggest';
import './tumBiyografi.scss';
import baseURL from "@/common";


class Biyografiler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '', //suggestionda seçilen değer
            suggestedBiyografies: [],
            biyografiler: [],
            pageSize: 0,
            pageNumber: 0,
            aramaMod: 0,
            tikladigiHarf: '',
        };
        this.latestRequest = null;
    }

    searchBiyografies = (ara, call, pageNumber = 1) => {
        const thisRequest = this.latestRequest =
            fetch(`${baseURL}Arama/Biyografiler?PageNumber=${pageNumber}&PageSize=10&kelime=${ara}&aramaMod=${this.state.aramaMod}`)
            .then(res => res.json())
            .then(
                (result) => {
                    // If this is true there's a newer request happening, stop everything
                    if (thisRequest !== this.latestRequest) {
                        return;
                    }
                    call(result)
                }
            )
    }

    handlePageClick = data => {
        alert('s?')
        let selectedIndex = data.selected;

        const call = (result) => {
            this.setState({
                biyografiler: result.data,
                pageSize: result.totalCount / result.pageSize,
                pageNumber: result.pageNumber - 1,
            });
            window.scrollTo(0, 0);
        }

        this.searchBiyografies(this.state.tikladigiHarf, call, selectedIndex + 1)
    };

    handleHarfClick = (harf) => {

        const call = (result) => {
            this.setState({
                biyografiler: result.data,
                pageNumber: result.pageNumber - 1,
                pageSize: result.totalCount / result.pageSize,
                tikladigiHarf: harf,
            })
        };
        this.setState({
            aramaMod: 1
        },() => {
            this.searchBiyografies(harf, call, 1)
        })

    }


    getSuggestions = (biyografiArr) => {
        return biyografiArr.filter(biyografi => biyografi.baslik);
    }

    getSuggestionValue = (biyografi) => {
        this.props.history.push({
            pathname: '/biyografiler/' + biyografi.sayfaId,
            state: {
                baslik: biyografi.baslik,
                icerik: biyografi.icerik,
            }
        });
        return biyografi.baslik;
    }

    renderSuggestion = (suggestion) => {
        return (
            <span>{suggestion.baslik}</span>
        );
    }

    onChange = (event, {newValue, method}) => {
        this.setState({
            value: newValue
        });
    };

    loadSuggestions = (value) => {
        setTimeout(() => {
            if (value === this.state.value) {

                const call = (result) => {
                    this.setState({
                        value: value,
                        suggestedBiyografies: this.getSuggestions(result.data)
                    });
                }
                this.setState({
                    aramaMod: 0
                }, () => {
                    this.searchBiyografies(value, call)
                })
            }
        }, 500);
    }

    onSuggestionsFetchRequested = ({value}) => {
        this.loadSuggestions(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestedBiyografies: []
        });
    };

    render() {
        const {value, suggestedBiyografies} = this.state;
        const inputProps = {
            placeholder: "Arayın..",
            value,
            onChange: this.onChange
        };

        const btnHarfStyle = 'btn mx-2 my-2 btn-md px-2 text-center text-white'
        const harfler = ['A', 'B', 'C', 'Ç', 'D', 'E', 'F', 'G', 'H', 'İ',
            'K', 'L', 'M', 'N', 'O', 'Ö', 'P', 'R', 'S', 'Ş',
            'T', 'U', 'Ü', 'V', 'Y', 'Z']

        const {biyografiler, pageSize} = this.state;

        return (
            <div>

                <Jumbotron hakkimizda={'Biyografiler'} jumbobaslik={'block'}/>

                <div className="container">
                    <div className="row mt-3 mb-3">
                        <Autosuggest
                            suggestions={suggestedBiyografies}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            onSuggestionSelected={this.onSuggestionSelected}
                            inputProps={inputProps}/>

                        <div className="mt-3 btn-toolbar" role="toolbar">
                            <div className="d-flex flex-wrap " role="group">
                                {harfler.map((value, index) => {
                                    return <button key={index}
                                                   onClick={() => this.handleHarfClick(value)}
                                                   type="button" style={{width: '30px', backgroundColor: '#3bb4c1'}}
                                                   className={btnHarfStyle}>{value}</button>
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3 mb-3">
                        {biyografiler.map(biyografi => (
                            <div key={biyografi.sayfaId} className="card shadow w-100 mt-3">
                                <div className="card-header">
                                    {biyografi.baslik}
                                </div>
                                <div className="card-body">
                                    <Truncate lines={2}>
                                        {ReactHtmlParser(biyografi.icerik)}
                                    </Truncate>
                                </div>
                                <div className="card-footer text-muted">
                                    <Link to={{
                                        pathname: '/biyografiler/' + biyografi.sayfaId,
                                        state: {
                                            baslik: biyografi.baslik,
                                            icerik: biyografi.icerik,
                                        }
                                    }}>
                                        <button className="button-oval text-white float-right">
                                            Oku
                                            <Icon name="arrow-next"/>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>



                    {biyografiler.length != 0 ? (
                    <div className="row mt-3 mb-3 justify-content-center">
                        <ReactPaginate
                            previousLabel={'Geri'}
                            nextLabel={'İleri'}
                            breakLabel={'...'}
                            pageCount={pageSize}
                            forcePage={this.state.pageNumber}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'page-item'}
                            activeClassName={'active'}
                        />
                    </div>) :    <h1 style={{textAlign: 'center', padding: '20px'}} >Arama veya Harf Filtrelemesi Yapabilirsiniz</h1> }


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        getSayfaReducer: state.getSayfaReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getBiyografiAction: bindActionCreators(getSayfaAction, dispatch)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Biyografiler);

