import React, {useState} from "react";
import Jumbotron from "@/Components/Jumbotron/jumbotron";
import {Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw,
    convertFromRaw, ContentState, convertFromHTML} from 'draft-js';

import {stateToHTML} from 'draft-js-export-html';
import baseURL from "@/common";
import ReactHtmlParser from "react-html-parser"


export default function KitapIcerikDuzenle(props) {
    const state = props.location.state

    const [ baslik, setBaslik ] = useState(state.baslik)
    const [ icerik, setIcerik ] = useState(state.icerik)

    const handleSubmit = async () => {
        const url = baseURL + "Icerik/" + state.kitapIcerikId;
        /*
        const data = {
            Baslik: baslik,
            Icerik: icerik
        }

        let response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(data)
        });

        console.log(response)
         */
        alert('DRAFT JS SUPERSCRİPT HATASI VAR, O ÇÖZÜLMESİ GEREKİYOR.')
        props.history.goBack()
    }


    return (
        <div>
            <Jumbotron hakkimizda={'Kitap İçerik Düzenle'} jumbobaslik={'block'} />
            <div className="row">
                <div className="col-10 mx-auto" >
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">İçerik Konusu: </span>
                        </div>
                        <input type="text"
                               value={baslik}
                               onChange={(e) => setBaslik(e.target.value)}
                               className="form-control"/>
                    </div>
                    <MyEditor icerik={icerik}
                            onIcerikChange={(newIcerik) => setIcerik(newIcerik)}
                    />
                    <button
                        onClick={handleSubmit}
                        type="button" className="mt-3 btn btn-success btn-md btn-block">Güncelle</button>
                </div>
            </div>
        </div>
    )
}

class MyEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = { editorState: EditorState.createEmpty() };

        this.focus = () => {
            if (this.editor) {
                this.editor.focus();
            }
        };
        this.onChange = (editorState) => {
            this.setState({editorState})
            const html = stateToHTML(this.state.editorState.getCurrentContent())
            console.log(html)
            this.props.onIcerikChange(html);
        };
        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    componentDidMount() {
        const { icerik } = this.props;

        const blocksFromHTML = convertFromHTML(icerik);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );

        const c = EditorState.createWithContent(state)
        this.setState({
            editorState: c
        })
    }

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }
    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }
    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }
    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {
        const {editorState} = this.state;

        let className = 'RichEditor-editor';
        const contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }
        return (
            <div className="RichEditor-root">
                {/*<BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />*/}
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                />
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        customStyleMap={{
                            SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
                            SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' }
                        }}
                        ref="editor"
                    />
                </div>
            </div>
        );
    }
}

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}
class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }
    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }
        return (
            <span className={className} onMouseDown={this.onToggle}>
              {this.props.label}
            </span>
        );
    }
}
const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
];
const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
var INLINE_STYLES = [
    {label: 'Kalın', style: 'BOLD'},
    {label: 'İtalik', style: 'ITALIC'},
    {label: 'Altı Çizili', style: 'UNDERLINE'},
    {label: 'SuperScript', style: 'SUPERSCRIPT'},
];
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
