import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import KitapTaslak from '../../../Components/KitapTaslak/kitap-taslak';
import { getAnasayfaAction } from '../../../Redux/Actions/anasayfaActions';
import Jumbotron from '../../../Components/Jumbotron/jumbotron';

class Dosyalar extends Component {
	
	componentDidMount() {
		this.props.actions.getDosyalarAction();
	}

	tumDosyalar = () => {
		const { getAnasayfaReducer } = this.props;
		if (getAnasayfaReducer) {
			return (
				<div className="row">
					{getAnasayfaReducer.dosyalar &&
						getAnasayfaReducer.dosyalar.map((item) => (
							<KitapTaslak
								key={item.dosyaId}
								dosyaId={item.dosyaId}
								foto={item.foto}
								baslik={item.baslik}
								isim={item.isim}
								detay={item.detay}
								link="dosyalar/detay"
							/>
						))}
				</div>
			);
		} else {
			return <div>Ansiklopedi Bulunamadi...</div>;
		}
	};

	render() {
		const display = 'block';
		const dosyalar = 'Dosyalar';
		return (
			<div>
				<Jumbotron hakkimizda={dosyalar} jumbobaslik={display} />
				<div className="DosyalarKismi mycontainer MarginDegeri">{this.tumDosyalar()}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		getAnasayfaReducer: state.getAnasayfaReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getDosyalarAction: bindActionCreators(getAnasayfaAction, dispatch)
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dosyalar);
