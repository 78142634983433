import React, {Component, useState} from 'react';
import Jumbotron from "../../Components/Jumbotron/jumbotron";
import baseURL from "../../common";


function KitapUpload() {
    const [ selectedFile, setSelectedFile ] = useState({});
  
    const [ yazar, setYazar ] = useState('');
    const [ baslik, setBaslik ] = useState('');
    const [ detay, setDetay ] = useState('');
    
    async function onYukleClick() {
        const data = new FormData()
        data.append('file', selectedFile)
        
        let url = baseURL + "Dosya/UploadFileToKitap" + `?Yazar=${yazar}&Baslik=${baslik}&Detay=${detay}`
              
        let response = await fetch(url, {
            method: 'POST',
            body: data
        });
        
        let res = await response.json();
        console.log(res)
        
        setYazar('');
        setBaslik('');
        setDetay('');
    }
    
    return (
        <div>
            <Jumbotron hakkimizda={"Kitap Yükle"} jumbobaslik={"block"} />

            <div className="col-md-6 col-lg-6 mx-auto" >
                <p>Bismillah</p>
                <p>Kitap Yükle...</p>
                
                <input 
                    value={yazar}
                    onChange={(e) => setYazar(e.target.value)} 
                    className="mr-2" type="text" placeholder="Yazar" />
                    
                <input 
                    value={baslik}
                    onChange={(e) => setBaslik(e.target.value)}
                    className="mr-2" type="text" placeholder="Baslik" />
                
                <input
                    value={detay}    
                    onChange={(e) => setDetay(e.target.value)} 
                    type="text" placeholder="Detay" />
                
                <input className="mt-2" type="file" name="file" 
                       onChange={(e) => setSelectedFile(e.target.files[0]) }/>
                       
                <button onClick={() => onYukleClick()} 
                    type="button" className="btn btn-success btn-block my-3">
                    Yükle
                </button>
            </div>
        </div>
    );
}

export default KitapUpload;