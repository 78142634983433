import { combineReducers } from 'redux';
import selectedKategori from './selectKategori';
import kitapReducer from './kitapReducer';
import ansiklopediReducer from './ansiklopediReducer';

import anasayfaReducer from './anasayfaReducer';
import icerikReducer from './icerikReducer';
import dosyaReducer from './dosyaReducer';
import sayfaReducer from './sayfaReducer';

const rootReducer = combineReducers({
	selectedKategori: selectedKategori, //secilen kategoriyi getirisn
	getAnsiklopedilerReducer: ansiklopediReducer,
	getAnasayfaReducer: anasayfaReducer,
	getKitapReducer: kitapReducer,
	getIcerikReducer: icerikReducer,
	getDosyaReducer: dosyaReducer,
	getSayfaReducer: sayfaReducer
});
export default rootReducer;

//oluşturulan reducer ların import edildiği yer
