import React, { Component } from 'react';

import sunum from './images/sunum.jpg'
import pdf from './images/pdf.jpg'
import doc from './images/doc.jpg'

import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

import KitapTaslak from '../KitapTaslak/kitap-taslak';
import Icon from '../Iconlar';

import $ from 'jquery';



window.jQuery = $;
window.$ = $;
global.jQuery = $;
class Dosyalar extends Component {
	arrowback = () => {
	 	$('.items').animate({ scrollLeft: '+=200' }, 500);
	};
	arrownext = () => {
	 	$('.items').animate({ scrollLeft: '-=200' }, 500);
	};
	componentDidMount = () => {
		const slider3 = document.querySelector('.items');

		let isDown = false;
		let startX;
		let scrollLeft;

		slider3.addEventListener('mousedown', (e) => {
			isDown = true;
			slider3.classList.add('active');
			startX = e.pageX - slider3.offsetLeft;
			scrollLeft = slider3.scrollLeft;
		});
		 slider3.addEventListener('mouseleave', (e) => {
		 	isDown = false;
		 	slider3.classList.remove('active');
		 });
		slider3.addEventListener('mouseup', () => {
			isDown = false;
			slider3.classList.remove('active');
		});
		slider3.addEventListener('mousemove', (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - slider3.offsetLeft;
			const walk = (x - startX) * 2; //scroll-fast
			slider3.scrollLeft = scrollLeft - walk;
		});
	};

	seciliDosyalar = () => {
		const { getAnasayfaDosyalar } = this.props;

		if (getAnasayfaDosyalar) {
				return (
					getAnasayfaDosyalar.map((item) => (
						<div key={item.dosyaId}>
							<KitapTaslak
								dosyaId={item.dosyaId}
								foto={pdf}
								baslik={item.baslik}
								isim={item.isim}
								detay={item.detay}
								link='dosyalar/detay'/>
						</div>
					))
				);
		} else {
			return <div>Dosya Bulunamadi...</div>;
		}
	};

	render() {
		return (
			<div className="Dosyalar-main  d-flex justify-content-md-end justify-content-sm-center ">
				<div className=" Dosyalar-main-icerik  col-md-11 col-sm-12 row  h-100  ">
					<div className="Dosyalar-main-icerik-solbolum col-md-5 ">
						<div className="dosyalar-main-aciklama">
							<Link to="/dosyalar">
								<p className="kucuk-baslik">Dosyalar</p>
							</Link>
							<div className="buyuk-baslik">Dosyalar</div>
							<div className="paragraf">
								The practice of cigar smoking has been on the rise in the U.S. since the early 90’s. In
								part due to a reputation as a glamorous alternative to cigarette smoking.
							</div>
						</div>
					</div>

					<div className="Dosyalar-main-icerik-sagbolum col-md-7 items ">{this.seciliDosyalar()}</div>

					<div className="Dosyalar-main-icerik-solaltbolum col-md-5  ">
						<span className="button-goruntule">
						<Link to="/dosyalar">
								<button className="button-oval">
									Tümünü Görüntüle
									<Icon name="arrow-next" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak gerigit" onClick={() => this.arrownext()}>
									<Icon name="arrow-back" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak ilerigit" onClick={() => this.arrowback()}>
									<Icon name="arrow-next" className="rotate90" />
								</button>
							</Link>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

//aksiyon ve state i baglama
export default  Dosyalar;
