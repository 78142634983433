import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { getKitaplarIcerikAction } from '../../Redux/Actions/icerikActions';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from "react-html-parser";
import ReactPaginate from 'react-paginate';


window.jQuery = $;
window.$ = $;
global.jQuery = $;
class Kitapindex extends Component {
	//kitabın içindekiler kısmını gösteren yapı.

	firstChild = '';
	
	
	
	componentDidMount() {
		$('.current').removeClass('current');
		
		this.selectFirstIcerik();

		
		$('ul li .abc').click(function() {
			$('.current').removeClass('current');
			$(this).addClass('current');
			$('.current').addClass('kalin');
		});
	}

	selectIndex = (id) => {
		return this.props.actions.getKitaplarIcerikAction(id);
	};
	selectFirstIcerik = () => {
		//kitap tıklandığında ilk index i otomatik olarak getirsin
		if (this.props.getKitapReducer.icindekiler) {
			const first = this.props.getKitapReducer.icindekiler[0];
			if (first) {
				this.selectIndex(first.kitapIcerikId);
				$('.sol-index .current-once li .abc:first').addClass('kalin current')
			}
		}
	};

	indexler = () => {
		const { getKitapReducer } = this.props;

		if (getKitapReducer) {
			return (
				<div>
					<div className="ansiklopedi">
						{getKitapReducer.icindekiler &&
							getKitapReducer.icindekiler.map((item, key) => (
								<ul key={item.kitapIcerikId} className="current-once">
									<li className="current" data-dismiss="modal">
										<div className="abc" onClick={() => this.selectIndex(item.kitapIcerikId)}>
											{ ReactHtmlParser (item.baslik) }
										</div>
									</li>
								</ul>
							))}
					</div>
				</div>
			);
		} else {
			return <div>index yok...</div>;
		}
	};

	render() {
		return (
			<div className="search-kitapindex">
				<div className="scroll">{this.indexler()}</div>
			</div>
		);
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getKitaplarIcerikAction: bindActionCreators(getKitaplarIcerikAction, dispatch)
		}
	};
};
export default connect(null, mapDispatchToProps)(Kitapindex);
