import React, {Component} from "react"
import AramaSonucu from "../AramaSonucu/aramaSonucu"
import ReactPaginate from 'react-paginate';


class LugatAramaSonucu extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filteredLugatSonuc: [],
            lugatSonuc: [],
            lugatPageSize: 10,
            lugatDilimBaslangic: 0,
            lugatMiAriyacak: false,
        }
    }

    componentDidMount() {
       const { lugatAra } = this.props // initial fetch
       console.log(lugatAra)
       this.lugatiAra(lugatAra)
    }

    componentDidUpdate(prevProps) { //to make the subsequent calls.
        if (prevProps.lugatAra !== this.props.lugatAra) {
            this.lugatiAra(this.props.lugatAra)
        }
    }

    lugatiAra(options) {
        console.log(options)
        if (options === undefined || options === null)
            return;
        
        let query = Object.keys(options)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(options[k]))
            .join('&');

        let url = 'https://imla.kabikavseyn.com/?' + query

        console.log(url)
        fetch(url)
            .then(res => res.json())
            .then((result) => {
                this.setState({
                    filteredLugatSonuc: result,
                    lugatSonuc: result
                })
            }).catch(function (error) {
            console.error(error)
        })
    }

    handlePageClick = data => {
        let selectedIndex = data.selected;

        const {lugatPageSize} = this.state;

        this.setState({
            lugatDilimBaslangic: selectedIndex * lugatPageSize
        })
    };

    manaImgAdresiDuzenle(mana) {
        const index = mana.indexOf("<img")

        const lastIndex = mana.indexOf("style=\"width:100%;max-width:480px;\">")

        let sliced = mana.slice(index, lastIndex) //<img src="/kamusuturki/rsm/turki/9/921-22.jpg"

        sliced = sliced.replace("src=\"", "src=\"http://lugat.osmanlica.online")

        const imgStr = sliced & sliced.replace("src=\"", "src=\"http://lugat.osmanlica.online")

        return mana.slice(0, index) + sliced + mana.slice(lastIndex, mana.Length)
    }

    isArabic(s) {
        const arabic = /[\u0600-\ufefc]/;
        if (s === '‘' || s === '“' || s === '”' || s === '‘')
            return false

        return arabic.test(s)
    }

    osmanlicaClassiEkle(mana) {
        let charArr = mana.split("")

        let osm = false
        let manaString = '';
        for (let s of charArr) {
            if (this.isArabic(s)) {
                if (!osm) {
                    manaString += "<span class=\"osmanlica\">"
                    osm = false;
                }
                osm = true
                manaString += s
            } else {
                if (osm) {
                    manaString += "</span>"
                    osm = false;
                }
                manaString += s
            }
        }

        return manaString
    }

    manayiFormatla(mana) {
        mana = this.manaImgAdresiDuzenle(mana)

        mana = this.osmanlicaClassiEkle(mana)

        return mana;
    }

    handleKubbeAltiFiltre = (filtrele) => {
        const {lugatSonuc} = this.state;

        if (filtrele) {
            this.setState({
                filteredLugatSonuc: lugatSonuc.filter(lugat => lugat.Kaynak === "Kubbe Altı")
            })
        } else {
            this.setState({
                filteredLugatSonuc: lugatSonuc
            })
        }
    }

    render() {
        const {filteredLugatSonuc, lugatDilimBaslangic, lugatPageSize, lugatSonuc} = this.state;

        const { lugatAra } = this.props

        return (
            <div className="row justify-content-start">
                {filteredLugatSonuc ? filteredLugatSonuc.slice(lugatDilimBaslangic, lugatDilimBaslangic + lugatPageSize).map(item => (
                    <AramaSonucu key={item.Sira}
                                 aradiLugat={lugatAra.filitre}
                                 onClick={this.handleKubbeAltiFiltre}
                                 baslik={item.Kelime + ' ' + item.Kaynak}
                                 mana={this.manayiFormatla(item.Mana)}
                    />
                )) : null}

                {filteredLugatSonuc.length != 0 ? (
                    <div className="row mt-3 mb-3 justify-content-center">
                        <ReactPaginate
                            previousLabel={'Geri'}
                            nextLabel={'İleri'}
                            breakLabel={'...'}
                            pageCount={filteredLugatSonuc.length / lugatPageSize}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'page-item'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default LugatAramaSonucu
