import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import "react-sweet-progress/lib/style.css";
import './custom.css';

import Jumbotron from '../../Components/Jumbotron/jumbotron';

import Kitapindex from '../../Components/Kitapindex/kitapindex';

import { bindActionCreators } from 'redux';
import {getKitaplarAction, searchKitapIcerik} from '../../Redux/Actions/kitapActions';

import { connect } from 'react-redux';

import $ from 'jquery';

import ReactHtmlParser from 'react-html-parser';

import ReactPaginate from 'react-paginate';
import { Progress } from 'react-sweet-progress';

import {progressArtis, progressInterval} from "../../common";
import Search from "../../Components/SearchButton/search";
import {getToken} from "@/utils";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

class KitapDetaylari extends Component {
	//Kitapindex 2 adet kullanılmştır. bunun sebebi siteye mobil den girenlere farklı bir tasarım sunmak .
	//bu yuzden Kitapindex ten herhangi birine yapılacak değişikliği diğerine de uygulamak lazım

	constructor(props) {
		super(props);
		
		this.state = {
			acilmaProgress: 0,
			totalIcindekilerCount: 0,
			aranacakText: '',
			isAuthored: false,
		}
		
		this.handlePaginateClick = this.handlePaginateClick.bind(this);
	}

	componentDidMount() {
		let { match } = this.props;
		let slug = match.params.slug;
		
		const { getKitapReducer } = this.props;


		this.props.actions.getKitapAction(slug, 1)
			.then((res) => this.setState({
				totalIcindekilerCount: res.payload.totalCount / res.payload.pageSize
			})); 
		
		/////////////////////////
		$('.current').removeClass('current');

		/////////////////////////

		$('.search-kitapindex').removeClass('show');
		$('.Search-arama-fullpagebutton').click(function() {
			$('.scroll').addClass('show');
		});

		const modal = document.getElementById('abc');

		//kullanıcı modal ın dışına herhangi bir yere tıkladığında modal kapansın
		window.onclick = function(event) {
			if (event.target === modal) {
				modal.style.display = 'none';
			}
		};

		if (getToken() !== undefined) {
			this.setState({
				isAuthored: true
			})
		}
		
		let interval = setInterval(() =>{
			this.setState({
				acilmaProgress: this.state.acilmaProgress + progressArtis
			}, () => {
				if (this.state.acilmaProgress > 100)
					clearInterval(interval)
			})
		}, progressInterval);

		
	}

	handleSearch = (aranacakText) => {
		let { match } = this.props;
		let slug = match.params.slug;
		
		if (aranacakText.length < 3) {
			if (aranacakText.length === 0) {
				// initial state'e al.
				this.props.actions.getKitapAction(slug, 1)
					.then((res) => this.setState({
						totalIcindekilerCount: res.payload.totalCount / res.payload.pageSize
					}));
				this.setState({
					aranacakText: ''
				})
			}
			return;
		}
		
		if (aranacakText === this.state.aranacakText)
			return;

		this.setState({
			aranacakText
		}, () => {
			this.props.actions.getKitapAction(slug, 1, this.state.aranacakText)
				.then((res) => this.setState({
					totalIcindekilerCount: res.payload.totalCount / res.payload.pageSize
				}));	
		} );
	};
	handleEnter = (event, aranacakText) => {
		if (event.key === "Enter")
			this.handleSearch(aranacakText)
	};
	
	handlePaginateClick(data) {
		let selectedIndex = data.selected;
		let { match } = this.props;

		let slug = match.params.slug;
		
		this.props.actions.getKitapAction(slug, selectedIndex + 1, this.state.aranacakText)
	}

	onChange = (event) => {
		let { match } = this.props;
		let slug = match.params.slug;
		
		const value = event.target.value

		this.props.actions.getKitapAction(slug, 1, value)
			.then((res) =>
				{
					this.setState({
						totalIcindekilerCount: res.payload.totalCount / res.payload.pageSize,
					});
				}
			);
	};
	
	handleIcerikEdit = () => {
		const { getIcerikReducer,history, getKitapReducer } = this.props;
		const { data: kitapData } = getKitapReducer;
		
		console.log(getIcerikReducer)	
		
		history.push({
			pathname: `/kitaplar/kitapdetaylari/${kitapData.baslik}/duzenle`,
			state: {
				baslik: getIcerikReducer.baslik,
				icerik: getIcerikReducer.icerik,
				kitapIcerikId: getIcerikReducer.kitapIcerikId
			}
		});
		
	}
	
	
	render() {
		const { getKitapReducer } = this.props;
		
		const { data: kitapData } = getKitapReducer;

		const { getIcerikReducer } = this.props;
		
		const { isAuthored } = this.state;
		
		
		if (kitapData && this.state.acilmaProgress > 100) {
			return (
				<div>
					<Jumbotron goster={'block'}
							   eserFoto={kitapData.foto }
							   eserIsmi={kitapData.baslik}
							   yazar={kitapData.yazar} />
	   
					<div className="KitapDetayKismi mycontainer  MarginDegeri">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-4   KitapDetayKismi-solkisim">
									<div className="kucuk-baslik">İçerisindekiler</div>


									{/* MOBİL İÇİN BURASI ÇALIŞIYOR. */}
									<div
										className="Search-arama-fullpagebutton     "
										data-toggle="modal"
										data-target="#myModal">
										<div className="ickisim cevir">
											{ ReactHtmlParser (getIcerikReducer.baslik) }
										</div>
									</div>

									<div
										className="modal fade mobilIndex"
										id="myModal"
										role="dialog"
										tabIndex="-1"
										aria-labelledby="myModalLabel"
										aria-hidden="true">
										<div className="modal-dialog">
											<div className="modal-content">
												<Kitapindex getKitapReducer={kitapData} />
											</div>
										</div>
									</div>
									{/* ----------------------- */}

									<Search getKitapReducer={kitapData}
											handleChange={this.onChange}
											onKeyDown={this.handleEnter}
											onClick={this.handleSearch}/>

											
									<div className="sol-index">
										<Kitapindex getKitapReducer={kitapData} />
										
										{this.state.totalIcindekilerCount > 1 ? 
											<ReactPaginate
												previousLabel={'<'}
												nextLabel={'>'}
												breakLabel={'...'}
												breakClassName={'break-me'}
												pageCount={this.state.totalIcindekilerCount}
												marginPagesDisplayed={1}
												pageRangeDisplayed={1}
												onPageChange={this.handlePaginateClick}
												containerClassName={'pagination my-3 justify-content-center'}
												subContainerClassName={'pages pagination'}
												activeClassName={'active'}
											/>
											: <div></div>}
									</div>
								</div>

								<div className="col-md-8   paragraf KitapDetayKismi-sagkisim">
									<div className="secili-index">
										{ ReactHtmlParser (getIcerikReducer.baslik) }
										{ isAuthored === true ?
											<button 
												onClick={this.handleIcerikEdit}
												type="button" className="btn btn-outline-primary float-right">DÜZENLE</button>
											: null
										}
										{ isAuthored === true ? <p style={{clear: "both"}}></p> : null }
									</div>
									{ ReactHtmlParser (getIcerikReducer.icerik) }
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
		else {
			
			const style = {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)'
			}
			
			return (<div style={style}  >
				<Progress
					type="circle"
					percent={this.state.acilmaProgress}
				/>
			</div>)
		}
	}
}
const mapStateToProps = (state) => {
	return {
		getKitapReducer: state.getKitapReducer,
		getIcerikReducer: state.getIcerikReducer,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getKitapAction: bindActionCreators(getKitaplarAction, dispatch),
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(KitapDetaylari);
