import React, {useState} from "react";
import Jumbotron from "@/Components/Jumbotron/jumbotron";
import baseURL from "@/common";

function AnsiklopediUpload() {
    const [ baslik, setBaslik ] = useState('');
    const [ selectedFile, setSelectedFile ] = useState({});
    const [ selectedJson, setSelectedJson ] = useState({});

    async function onYukleClick() {
        const data = new FormData();
        data.append('ansiklopediZip', selectedFile)
        data.append('kitapJson', selectedJson)

        let url = baseURL + "Dosya/UploadFilesToAnsiklopedi" + `?ansiklopediBaslik=${baslik}`

        let response = await fetch(url, {
            method: 'POST',
            body: data
        });

        let res = await response.json();
        console.log(res)
        setBaslik('')
    }
    
    return (
        <div>
            <Jumbotron hakkimizda={"Ansiklopedi Yükle"} jumbobaslik={"block"} />

            <div className="col-md-6 col-lg-6 mx-auto" >
                <p>Bismillah</p>
                <p>Ansiklopedi Yükle...</p>

                <input
                    value={baslik}
                    onChange={(e) => setBaslik(e.target.value)}
                    className="mr-2" type="text" placeholder="Başlık" />

                <p>------------------------------------</p>
                    
                <p >KİTAP BİLGİLERİNİ İÇEREN JSON DOSYASI</p>
                <input type="file" name="file"
                       onChange={(e) => setSelectedJson(e.target.files[0]) }/>

                <p>------------------------------------</p>
                       
                <p>ANSİKLOPEDİ ZİP</p>
                <input  type="file" name="file"
                       onChange={(e) => setSelectedFile(e.target.files[0]) }/>

                <button onClick={() => onYukleClick()}
                        type="button" className="btn btn-success btn-block my-3">
                    Yükle
                </button>
            </div>
        </div>
    );
    
    
}

export default AnsiklopediUpload;