import React from 'react'
import Left from './svg/Left'
import Right from './svg/Right'
import NavRight from './svg/NavRight'
import Opacity0 from './svg/Opacity0'
import ArrowNext from './svg/ArrowNext'
import ArrowBack from './svg/ArrowBack'
function Icon({ name }) {
    switch (name) {
      case 'left':
        return <Left />
      case 'right':
        return <Right />
        case 'navRight':
          return <NavRight />
          case 'opacity0':
            return <Opacity0 />
            case 'arrow-next':
              return <ArrowNext />
              case 'arrow-back':
                return <ArrowBack />
         
      default:
        return <></>

        
    }
  }
  
  export default Icon
  