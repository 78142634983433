import React, {Component} from 'react';
import FirstDropdown from '../SearchDropdowns/first-dropdown';
import SecondDropdown from '../SearchDropdowns/second-dropdown';
import CheckboxFiltre from '../CheckBoxFiltre/checkboxfiltre';
import Search from '../SearchButton/search';
import {connect} from "react-redux";
import {selectBook} from "../../Redux/Actions/selectKategoriAction"

import Select from "react-select";


const options = [
    { value: 'Dosyalar', label: 'Dosyalar', kod: 1 },
    { value: 'Biyografiler', label: 'Biyografiler', kod: 2 },
    { value: 'Lugatlar', label: 'Lügatlar', kod: 3 },
    { value: 'Ansiklopediler', label: 'Ansiklopediler', kod: 4 }
];

class SearchDetayli extends Component {
    
    state = {
        isVisible: false,
        inputText: '', //searchbox'un 
        selectedAramaTypeOption: null,
        lugatMiAriyacak: false,
        lugatHangisi: '-', // tüm lügatlar seçili çünkü.
        lugatSecenek: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.qAramaMod !== this.props.qAramaMod ||
            prevProps.qAraKelime !== this.props.qAraKelime) {
            
            const { qAramaMod, qAraKelime } = this.props
            //console.log(options.label)
            //console.log(qAramaMod, qAraKelime)

            options.map(opt => {
                if (opt.value.toLowerCase() === qAramaMod) {
                    this.setState({
                        selectedAramaTypeOption: opt,
                        inputText: qAraKelime
                    }, () => {
                        
                        if (opt.value === "Lugatlar") {
                            this.setState({
                                isVisible: true,
                                lugatMiAriyacak: true
                            })
                        }
                        else {
                            //this.handleSearch(qAraKelime)
                        }
                    })
                }
            })
        }
    }


    handleCategoryChange = (newCat) => {
        this.setState({
            selectedAramaTypeOption: newCat,
            inputText: '',
        })
        
        const secilen = newCat

        if (secilen.value === 'Lugatlar') {
            this.props.selectBook(secilen);
            this.setState({
                isVisible: true,
                lugatMiAriyacak: true
            });
        } else {
            this.props.selectBook(secilen);
            this.setState({
                isVisible: false,
                lugatMiAriyacak: false
            });
        }
        
        
        
    };


    handleLugatTypeChange = (selected) => {
        this.setState({
            lugatHangisi: selected
        })
    }

    handleLugatAramaType = (evt) => {
        this.setState({
            lugatSecenek: evt.map(obj => obj.value)
        })
    }

    handleSearch = (aranacakText) => {
        this.setState({
            inputText: aranacakText
        })
        
        const aramaTip = this.state.lugatMiAriyacak
        const sadeceHattiKuran = this.state.lugatSecenek || false
        const manadaAra = this.state.lugatSecenek || false

        if (this.state.lugatMiAriyacak) {
            const lugatOptions = {
                kelime: aranacakText,
                filitre: this.state.lugatHangisi,
                kaynak: 'browser',
                sadecehattikuran: sadeceHattiKuran && sadeceHattiKuran.includes("sadecehattikuran"),
                manadaara: manadaAra && manadaAra.includes("manadaara"),
                json: true
            }
            console.log("as")
            this.props.handleSearch(lugatOptions, true)
        }
        else {
            console.log("saaa")
            this.props.handleSearch(aranacakText, false)
        }
    };

    handleEnter = (event, aranacakText) => {
        if (event.key == "Enter")
            this.handleSearch(aranacakText)
    };

    render() {
        const {isVisible, inputText, selectedAramaTypeOption} = this.state;
        
        
        return (
            <div className="mycontainer ">
                <div className="SearchDetayli">
                    <div className="SearchDetayli-liste">

                        <div className="kategori   tumKategoriler-search boyutlandir">
                            <div>
                                <Select
                                    className="first"
                                    value={selectedAramaTypeOption}
                                    placeholder={'Seçiniz...'}
                                    onChange={this.handleCategoryChange}
                                    options={options}
                                    closeMenuOnSelect={true}
                                    isSearchable={false}
                                    styles={{
                                        container: (base, state) => ({
                                            ...base,
                                            zIndex: "999"
                                        }),
                                        dropdownIndicator: (base, state) => ({
                                            ...base,
                                            transition: 'all .2s ease',
                                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
                                        }),
                                        menu: (base, state) => ({
                                            ...base,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {isVisible ? (
                            <div className="kategori second tumLugatlar-search boyutlandir">
                                <div>
                                    <SecondDropdown onChange={(selected) => this.handleLugatTypeChange(selected)}/>
                                </div>
                            </div>
                        ) : null}
                        {isVisible ? (
                            <div className="kategori third tumFiltreler-search boyutlandir">
                                <div>
                                    <CheckboxFiltre
                                        onChange={this.handleLugatAramaType} />
                                </div>
                            </div>
                        ) : null}
                        
                        <div className="kategori tumSearch-search">
                            <div>
                                <Search inputValue={inputText}
                                        onKeyDown={this.handleEnter}
                                        onClick={this.handleSearch}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//connect(null,{selectBook}) redux a haber verdik
export default connect(null, {selectBook})(SearchDetayli);
