import React, { Component } from 'react'

class Card extends Component {
    render() {
        return (
            <div className="card-resim">


      
            <div className=" card">
            <a className="img-card" href="/">
            <img  alt="" 
            
            src="/images/faaliyet.png" />
          </a>
            <div className="card-content">
                <h4 className="card-title">
                    <a href="/"> {this.props.title}
                  </a>
                </h4>
                    </div>
            </div>
           
        </div>

        )
    }
}
export default Card