import React, {Component} from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';
import SearchDetayli from '../../Components/SearchDetayli/search-detayli';
import {connect} from 'react-redux';
import DosyaAramaSonucu from "../../Components/DosyalarAramaSonuc/dosyaAramaSonucu"
import AnsiklopediAramaSonucu from "../../Components/AnsiklopediAramaSonuc/ansiklopediAramaSonucu"
import BiyografiAramaSonucu from "../../Components/BiyografilerAramaSonuc/biyografiAramaSonucu"
import LugatAramaSonucu from "../../Components/LugatAramaSonuc/lugatAramaSonucu"
import queryString from 'query-string'


class Arama extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputText: '',
            selectedKategoriKod: 0,
            qAramaMod: null,
            qAraKelime: null,
            lugatOptions: null
        }
    }

    componentDidMount() {
        const { match } = this.props;
        const values = queryString.parse(this.props.location.search)
        if (match.path.includes('dosyalar')) {
            this.setState({
                selectedKategoriKod: 1,
                qAramaMod: 'dosyalar',
                qAraKelime: values.kelime,
                inputText: values.kelime
            })
        }
        else if (match.path.includes('biyografiler')) {
            this.setState({
                selectedKategoriKod: 2,
                qAramaMod: 'biyografiler',
                qAraKelime: values.kelime,
                inputText: values.kelime
            })
        }
        else if (match.path.includes('lugatlar')){
            this.setState({
                selectedKategoriKod: 3,
                qAramaMod: 'lugatlar',
                qAraKelime: values.kelime,
                lugatOptions: values
            })
        }
        else if (match.path.includes('ansiklopediler')){
            this.setState({
                selectedKategoriKod: 4,
                qAramaMod: 'ansiklopediler',
                qAraKelime: values.kelime,
                inputText: values.kelime,
            })
        }
        
    }

    
    

    componentDidUpdate(prevProps) {
        const {selectKategori} = this.props;

        if (selectKategori !== prevProps.selectKategori) {
            this.setState({
                inputText: ''
            })
        }
    }


    aramaYap(options, lugatMi = false) {
        const {selectKategori} = this.props;
        
        if (lugatMi === false) {
            console.log("sa")
            this.setState({
                inputText: options,
                selectedKategoriKod: selectKategori.kod === undefined ? this.state.selectedKategoriKod  : selectKategori.kod
            }, () => {
                let path = ''
                if (Object.entries(selectKategori).length === 0) {
                    path = `/arama/${this.state.qAramaMod}`
                }
                else {
                    path = `/arama/${selectKategori.value.toLowerCase()}`
                }

                this.props.history.push({
                    pathname: path,
                    search: "?kelime=" + options
                })
            })
        }
        else {
            let query = Object.keys(options)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(options[k]))
                .join('&');

            let path = ''
            if (Object.entries(selectKategori).length === 0) {
                path = `/arama/${this.state.qAramaMod}`
            }
            else {
                path = `/arama/${selectKategori.value.toLowerCase()}`
            }
            
            this.props.history.push({
                pathname: path,
                search: query
            })
            
            this.setState({
                lugatOptions: options,
                selectedKategoriKod: selectKategori.kod === undefined ? this.state.selectedKategoriKod  : selectKategori.kod
            })
        }
        
    }

    render() {

        const {inputText, lugatOptions, qAramaMod, qAraKelime} = this.state;

        const {selectKategori} = this.props;

        let kod;
        
        if (Object.entries(selectKategori).length === 0) {
            kod = this.state.selectedKategoriKod
        }
        else {
            kod = selectKategori.kod;
        }
        
        let sonuc;


        if (inputText !== '' || lugatOptions !== null) {
            switch (kod) {
                case 1:
                    sonuc = <DosyaAramaSonucu ara={inputText}
                                              qAramaMod={qAramaMod}  />
                    break;
                case 2:
                    sonuc = <BiyografiAramaSonucu ara={inputText}
                                                  qAramaMod={qAramaMod}/>
                    break;
                case 3:
                    sonuc = <LugatAramaSonucu lugatAra={lugatOptions}/>
                    break;
                case 4:
                    sonuc = <AnsiklopediAramaSonucu ara={inputText}
                                                    qAramaMod={qAramaMod}/>
                    break;
                default:
                    break;
            }
        }

        if (sonuc === undefined) {
            sonuc = <p></p>
        }

        return (
            <div>
                <Jumbotron hakkimizda={'Arama Sonuçları'} bottom={'0%'} jumbobaslik={'block'}/>

                <div className="KitapAramaKismi  MarginDegeri ">
                    <SearchDetayli
                        qAramaMod={qAramaMod}
                        qAraKelime={qAraKelime}
                        handleSearch={(options, lugatMi) => {
                            this.aramaYap(options, lugatMi)
                        }}
                        onChange={this.props.onChange}/>
                    <div className="container">
                        {sonuc}
                    </div>
                </div>
            </div>
        );
    }
}

const mapSateToProps = (state) => {
    return {
        selectKategori: state.selectedKategori
    };
};
export default connect(mapSateToProps)(Arama);
