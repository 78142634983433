import React, { Component } from 'react';
import Jumbotron from "../Jumbotron/jumbotron";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class BiyografiDetay extends Component {


    render() {
        const { baslik, icerik} = this.props.location.state
        return (
            <div>
                <Jumbotron hakkimizda={baslik} jumbobaslik="block" />

                <div className="container">
                    { ReactHtmlParser (icerik) }
                </div>
            </div>
        )
    }
}

export default BiyografiDetay;