import React, { Component } from 'react';
import Icon from '../Iconlar/index';
import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.jQuery = $;

class Navigation extends Component {
	componentDidMount = () => {
		////////////////////////////////////////////////////////////////////////
		$(document).ready(function() {
			$(document).click(function(event) {
				var clickover = $(event.target); //navigation dışına bir yere tıklandıgınd a nav i kaldırır

				var _opened = $('.navbar-collapse').hasClass('show');
				if (
					_opened === true &&
					!clickover.hasClass('navbar-toggler') &&
					!clickover.hasClass('dropdown-toggle')
				) {
					$('.navbar-toggler').click();
				}
			});
		});

		///////////////////////////////////////////////////////////
		$('.navbar-toggler').click(function() {
			//navigasyon a tıklandığında seklin X işaretine dönüşmesini saglar
			$('.navbar-toggler').toggleClass('open');
		});
		////////////////////////////////////////////////////////
		$(function() {
			//180px scroll yapıldıktan sonra navbar ı gölgelendir
			var navbar = $('.navbar');
			$(window).scroll(function() {
				if ($(window).scrollTop() <= 180) {
					navbar.css('box-shadow', 'none');
				} else {
					navbar.css('box-shadow', '0px 5px 25px rgba(0, 0, 0, 0.4)');
				}
			});
		});
	};

	render() {
		return (
			<div className=" Header-navigation mycontainer center">
				<nav className="navbar navbar-expand-md navbar-light bg-light">
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent "
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon" />
						<span className="navbar-toggler-icon" />
						<span className="navbar-toggler-icon" />
						<span className="navbar-toggler-icon" />
					</button>
					<Link className="Header-logo  " to="/">
						<img alt="" src="images/LogoNavigation.png" />
					</Link>

					<div className="collapse navbar-collapse sol " id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item ">
								<Link className="nav-link" to="/">
									Anasayfa
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/hakkimizda">
									Hakkımızda
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to="/faaliyetler">
									Faaliyetler
								</Link>
							</li>
							<li className="nav-item dropdown  ">
								<div
									className="nav-link dropdown-toggle"
									href="/"
									id="navbarDropdown"
									role="button"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false"
								>
									İçerikler
									<Icon name="arrow-next" />
								</div>
								<div className="dropdown-menu panel" aria-labelledby="navbarDropdown">
									<Link className="dropdown-item" to="/dosyalar">
										Dosyalar
									</Link>
									<div className="dropdown-divider" />
									<Link className="dropdown-item" to="/biyografiler">
										Biyografiler
									</Link>
									<div className="dropdown-divider" />
									<Link className="dropdown-item" to="/kitaplar">
										Kitaplar
									</Link>
									<div className="dropdown-divider" />
									<Link className="dropdown-item" to="/tumAnsiklopediler">
										Ansiklopediler
									</Link>
								</div>
							</li>
							<li className="nav-item">
								<Link className="nav-link " to="/iletisim">
									İletişim
								</Link>
							</li>
						</ul>
					</div>

					<Link className="aramayap" to={'arama'} >
						<img alt="" className="aramayap-icon" src="/images/search.png" />
					</Link>

				</nav>
			</div>
		);
	}
}

export default Navigation;
