const baseURL = process.env.REACT_APP_API_URL

let progressArtis;
let progressInterval;

if (process.env.NODE_ENV !== 'production') {
    progressArtis = 100
    progressInterval = 500
}
else {
    progressArtis = 15
    progressInterval = 500
}

export default baseURL

export {
    progressArtis,
    progressInterval
}