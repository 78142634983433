import React, { Component } from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';
import DosyaComponent from '../../Components/Dosyalar/dosyalar';
import KitaplarComponent from '../../Components/Kitaplar/kitaplar';
import AnsiklopedilerComponent from '../../Components/Ansiklopediler/ansiklopediler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAnasayfaAction } from '../../Redux/Actions/anasayfaActions';
import Biyografi from "@/Components/Biyografi/biyografi";
import KitapTaslak from "@/Components/KitapTaslak/kitap-taslak";
import Slider from "@/Components/Slider";

class HomePage extends Component {
	componentDidMount() {
		//anasayfa api sindeki tüm verileri çek ve bunu anasayfada gorunen componentlerin içerisine props ile gönder
		this.props.actions.getAnasayfaAction();
	}

	render() {
		
		const { getAnasayfaReducer } = this.props;

		return (
			<div>
				<Jumbotron
					resmigizle={'initial'}
					renk={'#15106A'}
					height={'680px'}
					arkaplanresmi={'/images/kavseynakademi.png'}
					display={'block'}
				/>
				
				<Biyografi {...getAnasayfaReducer.biyografi} />

				<DosyaComponent getAnasayfaDosyalar={getAnasayfaReducer.dosyalar} />

				<KitaplarComponent kitaplar={getAnasayfaReducer.kitaplar} />
				
				<AnsiklopedilerComponent getAnasayfaAnsiklopediler={getAnasayfaReducer.ansiklopediler} />
				<b>Ya Allah</b>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		//reducer a baglanma
		getAnasayfaReducer: state.getAnasayfaReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			//aksiyon a baglanma
			getAnasayfaAction: bindActionCreators(getAnasayfaAction, dispatch)
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
//redux a baglanma
