import React, { Component } from 'react';
import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik


class Footer extends Component {
	render() {
		return (
			<footer className="Footer       ">
				<div className="mycontainer  mt-5  ">
				<div className="footer-nav container-fluid row d-flex align-items-center   ">
                   
                    <div className="footer-nav-left     col-md-4  ">
						<div  >
							<img alt="" src="images/LogoNavigation.png" />
						</div>
					</div>
					<div className="footer-nav-right    col-md-8">
						<nav className="row ml-2">
							<div className="footer-link col-md-2 mr-3 ">
								<Link to="/">Anasayfa</Link>
							</div>
							<div className="footer-link col-md-2 mr-3">
								<Link to="/hakkimizda">Hakkımızda </Link>
							</div>
							<div className="footer-link col-md-2  mr-3">
								<Link to="/faaliyetler">Faaliyetler</Link>
							</div>
							<div className="footer-link col-md-2 mr-3">
								<Link to="/iletisim">İletişim</Link>
							</div>
						</nav>
					</div>
			
            
                </div>
                 
                	
            	</div>
			</footer>
		);
	}
}
export default Footer;


