import React, {Component} from "react";
import Jumbotron from "../../Components/Jumbotron/jumbotron";
import {Link} from "react-router-dom";

class DosyaDetay extends Component {
    render() {
        return (
            <div>
                <Jumbotron hakkimizda="Dosya İsmi Gelicek.." jumbobaslik="block" />

                <div className="HakkimizdaKismi mycontainer  MarginDegeri">
                    <div className="HakkimizdaKismi-bolumler flex-row container-fluid border-dark">
                        <div className="HakkimizdaKismi-bolumler-sol    ">
                            <div className=" flex-column  ">
                                <div>
                                    <img alt="" className="  fotograf" src="/images/dosyaornek1.png" />
                                </div>
                            </div>
                            
                            <div style={{marginTop: 20 + 'px'}}  className=" flex-column  ">
                                <div>
                                    <img alt="" className="  fotograf" src="/images/dosyaornek2.png" />
                                </div>
                            </div>
                        </div>
                        <div className="HakkimizdaKismi-bolumler-sag paragraf  ">
                            <div>
                                1. Lema Hakkında Hazırlanmıştır. <br/>

                                Risale-i Nurdan alıntılarla birlikte olayın özünü anlatır.
                                
                                
                                Dosya Detayı Gelicek...
                                .......
                                ...
                                ...
                            </div>
                            <div className="download">
                                <Link to="/">
                                    <img alt="" src="/images/download.png" />
                                    <span className="dosyayi-indir">Dosyayı İndir</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DosyaDetay