import React, {Component} from "react"
import baseURL from "../../common"
import Icon from "../Iconlar"
import {Link} from "react-router-dom"
import Truncate from "react-truncate"
import ReactHtmlParser from "react-html-parser";
import ReactPaginate from 'react-paginate';


class BiyografiAramaSonucu extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            biyografiSonuc: [],
            biyografiPageSize: 0,
            biyografiPageNumber: 1,
            ara: '',
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.qAramaMod !== "biyografiler") {
            return {
                ara: ''
            }
        }
        else {
            return {
                ara: props.ara
            }
        }
    }

    componentDidMount() {
        const {ara} = this.state
        if (ara !== ''){
            this.biyografiAra(ara)
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.ara !== this.props.ara) {
            this.biyografiAra(this.props.ara)
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    biyografiAra(ara) {
        const url = baseURL + "Arama/Biyografiler?PageSize=10&kelime=" + ara
        this._isMounted = true
        return fetch(url)
            .then((response) => response.json())
            .then((result) => {
                    if (this._isMounted) {
                        this.setState({
                            biyografiSonuc: result.data,
                            biyografiPageSize: result.totalCount / result.pageSize,
                            biyografiPageNumber: result.pageNumber - 1
                        })
                    }
                }
            );
    }

    handleBiyografiPageClick = data => {
        let selectedIndex = data.selected;

        const call = (result) => {
            this.setState({
                biyografiSonuc: result.data,
                biyografiPageSize: result.totalCount / result.pageSize,
                biyografiPageNumber: result.pageNumber - 1,
            });
            window.scrollTo(0, 0);
        }

        //this.searchBiyografies(this.props.ara, call, selectedIndex + 1)
        this.searchBiyografies(this.state.ara, call, selectedIndex + 1)
    };

    searchBiyografies = (ara, call, pageNumber = 1) => {
        const thisRequest = this.latestRequest = fetch(`https://akademi.kabikavseyn.com/api/Arama/Biyografiler?PageNumber=${pageNumber}&PageSize=10&kelime=${ara}`)
            .then(res => res.json())
            .then(
                (result) => {
                    // If this is true there's a newer request happening, stop everything
                    if (thisRequest !== this.latestRequest) {
                        return;
                    }
                    call(result)
                }
            )
    }

    render() {
        const {biyografiSonuc, biyografiPageSize, biyografiPageNumber} = this.state

        return (
            <div className="row justify-content-start">
                {biyografiSonuc && biyografiSonuc.map(biyografi => (
                    <div key={biyografi.sayfaId} className="card shadow w-100 mt-3">
                        <div className="card-header">
                            {biyografi.baslik}
                        </div>
                        <div className="card-body">
                            <Truncate lines={2}>
                                {ReactHtmlParser(biyografi.icerik)}
                            </Truncate>
                        </div>
                        <div className="card-footer text-muted">
                            <Link to={{
                                pathname: '/biyografiler/' + biyografi.sayfaId,
                                state: {
                                    baslik: biyografi.baslik,
                                    icerik: biyografi.icerik,
                                }
                            }}>
                                <button className="button-oval text-white float-right">
                                    Oku
                                    <Icon name="arrow-next"/>
                                </button>
                            </Link>
                        </div>
                    </div>
                ))}

                {biyografiSonuc.length != 0 ? (
                    <div className="row mt-3 mb-3 justify-content-center">
                        <ReactPaginate
                            previousLabel={'Geri'}
                            nextLabel={'İleri'}
                            breakLabel={'...'}
                            pageCount={biyografiPageSize}
                            forcePage={biyografiPageNumber}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handleBiyografiPageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'page-item'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}
            </div>

        )
    }
}

export default BiyografiAramaSonucu
