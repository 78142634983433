import React, {Component} from 'react'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = { aranacakText: '' }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //console.log(this.props)           
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log(prevProps.inputValue + "?", prevState.inputValue + "?")
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            aranacakText: nextProps.inputValue
        })
    }


    handleChange(event) {
        this.setState({aranacakText: event.target.value});
    }
    
    render() {
        return (
            <div className="Search-arama-yap">

                <input type="text"
                       value={this.state.aranacakText}
                       onChange={this.handleChange}
                       onKeyDown={(e) => { this.props.onKeyDown(e, this.state.aranacakText) }}
                       className="form-control arama-yap-input"
                       placeholder="Arayın..."/>

                <img alt="" className="arama-yap-icon"
                     onClick={() => { this.props.onClick(this.state.aranacakText) }}
                     src="/images/search.png"/>

            </div>

        )
    }
}

export default Search