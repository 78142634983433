import React, { Component } from 'react';
 import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

class Kitaptaslak extends Component {


	render() {
		const { link } = this.props;
		const { foto, baslik,  dosyaId } = this.props;

		const a = baslik.replace(/ /g,"-");
		
		return (
			<div className="kitap-taslak" >
				<Link to={link + '/' + a}>
					<div>
						<button className="button-pdfwordpower">PDF</button>
					</div>

					<img alt="kalem" className="kitap-taslak-resim" src={foto} />
					<div>
						<div className="kitap-baslik">
							<p>{baslik}</p>
						</div>
					</div>
				</Link>

				<div className="download">
					<Link to="/">
						<img alt="" src="/images/download.png" />
						<span className="dosyayi-indir">Dosyayı İndir</span>
					</Link>
				</div>
			</div>
		);
	}
}

export default  Kitaptaslak;
