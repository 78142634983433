export default function ansiklopediReducer(state = [], action) {
	switch (action.type) {
		case 'GET_ANSIKLOPEDI_BYID':
			return action.payload;

		default:
			// debugger;
			return state;
	}
}
