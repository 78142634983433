import React, { Component } from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';
import Kart from '../../Components/Card/card';
import data from "../../Data/data.json";
class Faaliyetler extends Component {
	render() {
		const display = 'block';
		const faaliyetler = 'Faaliyetler';
		return (
			<div>
				<Jumbotron hakkimizda={faaliyetler} jumbobaslik={display} />
				<div className="FaaliyetlerKismi MarginDegeri">
					<section className="wrapper">
						<div className="container-fostrap">
							<div className="content">
								<div className="container">
									<div className="row">
										{data.faaliyetler.map(item=>
											
										<div className="col-sm-6 col-md-4" key={item.id}>
											<Kart title={item.title}/>
										</div>	
											)}
										
										 
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}
}
export default Faaliyetler;
