import React, { Component } from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';

class Hakkimizda extends Component {
	render() {
		return (
			<div>
				<Jumbotron hakkimizda={'Hakkımızda'} jumbobaslik={'block'} />

				<div className="HakkimizdaKismi mycontainer  MarginDegeri">
					<div className="HakkimizdaKismi-bolumler flex-row container-fluid border-dark">
						<div className="HakkimizdaKismi-bolumler-sol    ">
							<div className=" flex-column  ">
								<div className="  ">
									<img alt="" className="  fotograf" src="/images/cocuk.jpg" />
								</div>
							</div>
						</div>
						<div className="HakkimizdaKismi-bolumler-sag paragraf  ">
							Laborum officia voluptate ex sit anim esse sit fugiat cupidatat. Laboris nulla consequat
							deserunt aliquip sunt reprehenderit in nulla nisi elit sint consectetur. Ut veniam dolore ea
							fugiat consequat dolore eu ad reprehenderit consequat. Cupidatat veniam nulla mollit ex
							reprehenderit. Id reprehenderit non in cillum velit mollit nostrud velit elit dolor minim.
							Cillum sunt non dolor exercitation eiusmod laboris voluptate aliqua nisi amet quis ipsum
							proident. Aliquip excepteur nisi ut adipisicing enim minim velit. Cupidatat sit labore elit
							ea consectetur excepteur commodo enim ea elit aute consequat Lorem eu. Amet deserunt amet
							laborum proident consectetur mollit ut. Pariatur tempor consequat magna cillum ipsum et eu
							do pariatur duis ad. Exercitation eiusmod dolore amet in in excepteur exercitation sit
							deserunt nostrud labore reprehenderit pariatur. Dolore culpa dolor cillum in velit do
							commodo dolore minim nisi dolor voluptate. Ipsum minim laborum velit id ipsum proident
							cillum ipsum in sunt. Qui labore irure est mollit consequat quis reprehenderit. Sit
							incididunt dolore nulla aute voluptate. Id aliqua duis ex aliquip incididunt pariatur sint
							veniam sint mollit nisi laboris dolor nisi. Velit duis minim cillum qui elit reprehenderit.
							Culpa laborum ut eu consequat adipisicing aliquip in in nulla qui esse occaecat minim ad.
							Deserunt consectetur irure eiusmod non aliqua non cupidatat quis velit exercitation minim.
							Esse velit veniam incididunt ea mollit nulla reprehenderit reprehenderit laboris aliquip
							nisi fugiat deserunt. Enim reprehenderit est ut qui incididunt magna culpa eu officia et.
							Cupidatat sunt qui aliquip aute enim. Magna commodo esse reprehenderit ullamco aute. Enim
							velit eiusmod minim id exercitation laboris consectetur dolor. Eu consectetur ad velit
							tempor reprehenderit consequat ullamco id. Tempor anim sint ipsum aliquip eu veniam sint
							proident consequat aute. Aute qui labore fugiat id nostrud nostrud.
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Hakkimizda;
