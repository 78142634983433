import baseURL from '../../common';

export function getSayfaActionSuccess(result) {
    return {
        type: 'GET_SAYFA',
        payload: result
    }
}

export function getSayfaAction(sayfaTur) {
    return function(dispatch) {
        const url = baseURL + "Sayfa?sayfaTur=" + sayfaTur;

        return fetch(url)
            .then((response) => response.json())
            .then((result) => dispatch(dispatch(getSayfaActionSuccess(result))));
    }
}


