import React from 'react';
import './Assets/css/main.css';
import Footer from './Components/Footer/footer';
import { Route, Switch } from 'react-router-dom';
import HomePage from './Pages/home/home';
import Hakkimizda from './Pages/hakkimizda/hakkimizda';
import Faaliyetler from './Pages/faaliyetler/faaliyetler';
import Iletisim from './Pages/iletisim/iletisim';
import ErrorPage from './Pages/error/error';
import KitapDetay from './Pages/kitap-detaylari/kitapDetay';
import Arama from './Pages/arama/arama';
import DosyaDetay from './Pages/dosya-detaylari/dosyaDetay'
import Ansiklopediler from './Pages/ansiklopediler/ansiklopedilerPage';
import Dosyalar from './Pages/icerikDropdown/tumDosyalar/tumDosyalar';
import Biyografiler from './Pages/icerikDropdown/tumBiyografi/tumBiyografi';
import Kitaplar from './Pages/icerikDropdown/tumKitap/kitap';
import TumAnsiklopediler from './Pages/icerikDropdown/tumAnsiklopedi/tumAnsiklopedi';
import ScrollToTop from "./Components/ScrollToTop";
import Navigation from "./Components/Navigation/navigation";
import BiyografiDetay from "./Components/BiyografiDetay/biyografiDetay";
import KitapUpload from "@/Pages/kitap-upload";
import AnsiklopediUpload from "@/Pages/ansiklopedi-upload";
import BiyografiAramaSonucu from "@/Components/BiyografilerAramaSonuc/biyografiAramaSonucu";
import KitapIcerikDuzenle from '@/Pages/KitapIcerikDuzenle'
import {getToken} from "@/utils";
import LoginPage from "@/Pages/login";
import {Redirect} from "react-router";


function App() {
	return (
		<div className="ana-kapsul">
			<Navigation />
			<main className="Main">
				<ScrollToTop/>
				<Switch>
					<Route exact path="/" component={HomePage} />
					<Route exact path="/hakkimizda" component={Hakkimizda} />
					<Route exact path="/faaliyetler" component={Faaliyetler} />
					<Route exact path="/iletisim" component={Iletisim} />

					<Route exact path="/biyografiler" component={Biyografiler} />

					<Route exact path="/biyografiler/:sayfaId" component={BiyografiDetay} />

					<Route exact path="/kitaplar" component={Kitaplar} />
					<Route exact path="/dosyalar" component={Dosyalar} />

					<Route exact path="/dosyalar/detay/:dosyaId" component={DosyaDetay} />

					<Route exact path="/ansiklopediler/:slug" component={Ansiklopediler} />

					<Route exact path="/ansiklopediler/kitapdetaylari/:slug" component={KitapDetay} />

					<Route exact path="/kitaplar/kitapdetaylari/:slug" component={KitapDetay} />

					<Route exact path="/kitaplar/kitapdetaylari/:slug/duzenle" 
						   render={(props) => {
						   		if (getToken() === undefined)
						   			return <Redirect to="/giris" />
							   return <KitapIcerikDuzenle {...props} />
						   }}/>
					
					<Route
						path="/arama"
						render={({ match: { url } }) => (
							<>
								<Route path={`${url}`} component={Arama} exact />
								<Route path={`${url}/biyografiler/`} component={Arama}  />
								<Route path={`${url}/dosyalar/`} component={Arama}  />
								<Route path={`${url}/lugatlar/`} component={Arama}  />
								<Route path={`${url}/ansiklopediler/`} component={Arama}  />
								<Route component={ErrorPage} />
							</>
						)}
					/>

					<Route exact path="/tumAnsiklopediler" component={TumAnsiklopediler} />
					<Route exact path="/kitap-upload" component={KitapUpload} />
					<Route exact path="/ansiklopedi-upload" component={AnsiklopediUpload} />
					
					<Route component={ErrorPage} />
				</Switch>
			</main>
			<Footer />
		</div>
	)
}


export default App;
