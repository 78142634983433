 
export function selectBook(book) {
	return {
		type: 'ACT_SELECT_KATEGORI',
		payload: book
	};
} 
//kitapArama sayfasında secilen kategorinin gösterimi için kullanıldı
///secilen kategorinin value degerini alıp baska component te gostermek için yaptık

  