import React, { Component } from 'react';
import Icon from '../Iconlar/index';
import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

//tümünü görüntüle gibi butonların componenti
class Buttonuzun extends Component {
	render() {
		const { gonder, color, width,  submit } = this.props;
		// const {link}=this.props
		return (
			<Link to="">
				<button type={submit} className="button-oval" style={{ color: color, width: width }}>
					{gonder}
					<Icon name="arrow-next" />
				</button>
			</Link>
		);
	}
}
export default Buttonuzun;
