import React, { Component } from 'react';
import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

import KitapTaslak from '../KitapTaslak/kitap-taslak';
import Icon from '../Iconlar';
import BookSlider from "@/Components/Slider";


class Ansiklopediler extends Component {

	constructor(props) {
		super(props);

		this.state = {
			ansiklopediSliderRef: {}
		}
		this.getSliderRef = this.getSliderRef.bind(this)
		this.sliderGeriGit = this.sliderGeriGit.bind(this);
		this.sliderIleriGit = this.sliderIleriGit.bind(this);
	}

	getSliderRef(ref) {
		this.setState({
			ansiklopediSliderRef: ref.current
		})
	}

	sliderGeriGit(evt) {
		evt.preventDefault();
		const { ansiklopediSliderRef } = this.state
		ansiklopediSliderRef.slickPrev()
	}

	sliderIleriGit(evt) {
		evt.preventDefault();
		const { ansiklopediSliderRef } = this.state
		ansiklopediSliderRef.slickNext()
	}

	render() {
		const { getAnasayfaAnsiklopediler } = this.props;

		const ansArr = [
			{ ansiklopediId: 22,
				baslik: 'İslam Fıkhi Ansiklopedisi',
				foto: "/upload/kapaklar/b2500877-cac2-430c-a31c-35a1f874d83c.png",
				kitaplar: null,
				 },
			{ ansiklopediId: 23,
				baslik: 'İslam Fıkhi Ansiklopedisi',
				foto: "/upload/kapaklar/b2500877-cac2-430c-a31c-35a1f874d83c.png",
				kitaplar: null,
				},
			{ ansiklopediId: 24,
				baslik: 'İslam Fıkhi Ansiklopedisi',
				foto: "/upload/kapaklar/b2500877-cac2-430c-a31c-35a1f874d83c.png",
				kitaplar: null,
				 },
			{ ansiklopediId: 25,
				baslik: 'İslam Fıkhi Ansiklopedisi',
				foto: "/upload/kapaklar/b2500877-cac2-430c-a31c-35a1f874d83c.png",
				kitaplar: null,
			},
			{ ansiklopediId: 26,
				baslik: 'İslam Fıkhi Ansiklopedisi',
				foto: "/upload/kapaklar/b2500877-cac2-430c-a31c-35a1f874d83c.png",
				kitaplar: null,
			}
		]

		return (
			<div className="Ansiklopediler-main  d-flex justify-content-md-end justify-content-sm-center ">
				<div className=" Dosyalar-main-icerik  col-md-12 col-sm-12 row  h-100  ">
					<div className="Dosyalar-main-icerik-solbolum col-md-4      ">
						<div className="dosyalar-main-aciklama">
							<Link to="">
								<p className="kucuk-baslik">Kavseyn</p>
							</Link>
							<div className="buyuk-baslik">Ansiklopediler</div>
							<div className="paragraf">
								The practice of cigar smoking has been on the rise in the U.S. since the early 90’s. In
								part due to a reputation as a glamorous alternative to cigarette smoking.
							</div>
						</div>
					</div>

					<div className="col-md-8" >

						{getAnasayfaAnsiklopediler &&
							<BookSlider
								action={this.getSliderRef}
								sliderId={"ansiklopedi"}
								>
								{getAnasayfaAnsiklopediler.map((item) => (
									<KitapTaslak
										key={item.ansiklopediId}
										foto={item.foto}
										baslik={item.baslik}
										yazar={item.yazar}
										dosyaId={item.ansiklopediId}
										link="ansiklopediler"
									/>
								))}
							</BookSlider>
						}
					</div>

					<div className="Dosyalar-main-icerik-solaltbolum col-md-4 ">
						<span className="button-goruntule">
							<Link to="/tumAnsiklopediler">
								<button className="button-oval">
									Tümünü Görüntüle
									<Icon name="arrow-next" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak ilerigit"
										onClick={this.sliderGeriGit}>
									<Icon name="arrow-back" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak gerigit"
										onClick={this.sliderIleriGit}>
									<Icon name="arrow-next" className="rotate90" />
								</button>
							</Link>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

export default Ansiklopediler;
