import React, {Component} from "react"
import baseURL from "../../common"
import Kitaptaslak from "../KitapTaslak/kitap-taslak"



class AnsiklopediAramaSonucu extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            ansiklopediSonuc: [],
            ara: '',
        }
    }

    static getDerivedStateFromProps(props) {
        if (props.qAramaMod !== "ansiklopediler") {
            return {
                ara: ''
            }
        }
        else {
            return {
                ara: props.ara
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
console.log("cagir")
        const {ara} = this.state
        if (ara !== ''){
            this.ansiklopediAra(ara)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ara !== this.props.ara) {
            this.ansiklopediAra(this.props.ara)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    ansiklopediAra(ara) {
        const url = baseURL + "Arama/Ansiklopedi?kelime=" + ara

        return fetch(url)
            .then((response) => response.json())
            .then((result) => {
                if (this._isMounted === true) {
                    this.setState({
                        ansiklopediSonuc: result.data
                    })    
                }
            });
    }


    render() {
        const {ansiklopediSonuc} = this.state

        return (
            <div className="row justify-content-start">
                {ansiklopediSonuc &&
                ansiklopediSonuc.map((item, index) => (
                    <div key={index} className="col-xs-6 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Kitaptaslak
                            key={item.ansiklopediId}
                            foto={item.foto}
                            baslik={item.baslik}
                            yazar={item.yazar}
                            dosyaId={item.ansiklopediId}
                            link="ansiklopediler"
                        />
                    </div>
                ))}
            </div>
        )
    }
}

export default AnsiklopediAramaSonucu
