import React, {Component} from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { filterOptions } from '../../Data/data.json';

class Checkboxfiltre extends Component {

	state = {
		selectedOption: null,
	};

	handleChange = selectedOption => {
		this.setState(
			{ selectedOption },
		);
		this.props.onChange(selectedOption)
	};

	render() {
		const animatedComponents = makeAnimated();
		const filterler = 'Filtreler';
		const { selectedOption } = this.state;

		return (
			<Select
				className="Checkboxfiltre third"
				closeMenuOnSelect={false}
				blurInputOnSelect={false}
				isSearchable={false}
				components={animatedComponents}
				placeholder={filterler}
				isMulti
				value={selectedOption}
				options={filterOptions}
				onChange={this.handleChange}
				noOptionsMessage={() => null}
				styles={{
					dropdownIndicator: (base, state) => ({
						...base,
						transition: 'all .2s ease',
						transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
					})
				}}
			/>
		);
	}
}

export default Checkboxfiltre;
