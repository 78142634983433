import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-alice-carousel/lib/alice-carousel.css';

import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';

import { Provider } from 'react-redux'; //sayesinde react redux beraber yonetilebir
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './Redux/Reducers/a-index';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Hakkimizda from "@/Pages/hakkimizda/hakkimizda";
import LoginPage from "@/Pages/login";
const store = createStore(reducers, applyMiddleware(thunk));


ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<Switch>
				<Route exact path="/giris" component={LoginPage} />
				<Route path="/" name="HomeLayout" 
					   render={props => <App {...props}/>} />
			</Switch>
		</Provider>{' '}
	</BrowserRouter>,
	document.getElementById('root'),
);

