import React, { Component } from 'react';
import { Link } from 'react-router-dom'; //bu sayfada link işi old için ekledik

import ReactHtmlParser from 'react-html-parser';

class Biyografi extends Component {
	biyografi = () => {

		if (this.props) {
			return (
				<div className="biyografi ">
					<div className="biyografi-aciklama  ">
						<Link to="/biyografiler">
							<p className="kucuk-baslik">Biyografiler</p>
						</Link>


						<Link to={{
							pathname: '/biyografiler/' + this.props.sayfaId,
							state: {
								baslik: this.props.baslik,
								icerik: this.props.icerik,
							}
						}}>
							<p className="buyuk-baslik"> {this.props.baslik}</p>

							<div className="paragraf" style={{overflow: 'hidden'}}>
								{ ReactHtmlParser (this.props.icerik) }
							</div>
						</Link>

					</div>

					<div className=" biyografi-resimalani">
						<img alt="" className="biyografi-orta fotograf  " src="/images/cocuk.jpg" />
					</div>
				</div>
			);
		} else {
			//debugger;
			return <div>Kitap Bulunamadi...</div>;
		}
	};

	render() {
		return <div className="BiyografiComponent mycontainer container-fluid">{this.biyografi()}</div>;
	}
}
export default Biyografi;
