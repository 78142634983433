import React, { Component } from 'react';
import { Link } from "react-router-dom"; //bu sayfada link işi old için ekledik

import KitapTaslak from '../KitapTaslak/kitap-taslak'
import Icon from '../Iconlar'
import BookSlider from "@/Components/Slider";

class Kitaplar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			kitapSliderRef: {}
		}
		this.getSliderRef = this.getSliderRef.bind(this)
		this.sliderGeriGit = this.sliderGeriGit.bind(this);
		this.sliderIleriGit = this.sliderIleriGit.bind(this);
	}


	getSliderRef(ref) {
		this.setState({
			kitapSliderRef: ref.current
		});
	}

	sliderGeriGit(evt) {
		evt.preventDefault();
		const { kitapSliderRef } = this.state

		if (kitapSliderRef.slickPrev === undefined) return;
		kitapSliderRef.slickPrev()
	}

	sliderIleriGit(evt) {
		evt.preventDefault();
		const { kitapSliderRef } = this.state

		if (kitapSliderRef.slickNext === undefined) return;
		kitapSliderRef.slickNext()
	}

	render() {
		const { kitaplar } = this.props;

		const kitapArr = [
			{ansiklopediId: 1, kitapId: 1, dosyaId: 1, baslik: 'deneme'},
			{ansiklopediId: 2, kitapId: 2, dosyaId: 2, baslik: 'deneme'},
			{ansiklopediId: 2, kitapId: 3, dosyaId: 2, baslik: 'deneme'},
			{ansiklopediId: 2, kitapId: 4, dosyaId: 2, baslik: 'deneme'},
		]

		return (
			<div className="  Lugatlar-main d-flex justify-content-md-start justify-content-sm-center ">
				<div className=" Dosyalar-main-icerik  col-sm-12 col-md-12  row  h-100  ">


					{kitaplar && kitaplar.length >= 4 &&
						<div className="Dosyalar-main-icerik-sagbolum col-md-8">
							<BookSlider
								action={this.getSliderRef} >
								{kitaplar.map(item => (
									<KitapTaslak
										key={item.kitapId}
										dosyaId={item.kitapId}
										foto={item.foto}
										baslik={item.baslik}
										link="/kitaplar/kitapdetaylari"
									/>
								))}
							</BookSlider>
						</div>
					}


					{kitaplar && kitaplar.length < 4 &&
						<div className="Dosyalar-main-icerik-sagbolum col-md-8" style={{display: 'flex', flexDirection: 'row'}} >
							{kitaplar.map(item => (
								<KitapTaslak
									key={item.kitapId}
									dosyaId={item.kitapId}
									foto={item.foto}
									baslik={item.baslik}
									link="/kitaplar/kitapdetaylari"
								/>
							))}
						</div>
						}

					{/*
					<div className="   Dosyalar-main-icerik-sagbolum col-md-8" >

						{kitaplar && kitaplar.length >= 4 &&
							<BookSlider
								settings={settings}
								action={this.getSliderRef} >
									{kitaplar.map(item => (
										<KitapTaslak
											key={item.kitapId}
											dosyaId={item.kitapId}
											foto={item.foto}
											baslik={item.baslik}
											link="/kitaplar/kitapdetaylari"
										/>
									))}
							</BookSlider>
						}

						{kitaplar && kitaplar.length < 4 && kitaplar.map(item => (
								<KitapTaslak
									key={item.kitapId}
									dosyaId={item.kitapId}
									foto={item.foto}
									baslik={item.baslik}
									link="/kitaplar/kitapdetaylari"
								/>
							))}

                    </div >*/}
					<div className="Dosyalar-main-icerik-solbolum col-sm-12 col-md-4">
						<div className="dosyalar-main-aciklama">
							<Link to="/kitaplar">
								<p className="kucuk-baslik">Kitaplar</p>
							</Link>
							<div className="buyuk-baslik">Kitaplar</div>
							<div className="paragraf">
								The practice of cigar smoking has been on the rise in the U.S. since the early 90’s. In
								part due to a reputation as a glamorous alternative to cigarette smoking.
							</div>
						</div>
					</div>
					<div className="Dosyalar-main-icerik-solaltbolum col-sm-12 col-md-4">
						<span className="button-goruntule">
							<Link to="/kitaplar">
								<button className="button-oval">
									Tümünü Görüntüle
									<Icon name="arrow-next" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak ilerigit"  onClick={this.sliderGeriGit}>
								<Icon name="arrow-back" />
								</button>
							</Link>
							<Link to="">
								<button className="button-yuvarlak gerigit"  onClick={this.sliderIleriGit}>
								<Icon name="arrow-next" className="rotate90"/>

								</button>
							</Link>
						</span>
					</div>
				</div>
			</div>
		);
	}
}
export default Kitaplar;
