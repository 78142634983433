import React, {Component, createRef} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class BookSlider extends Component {

    constructor(props, context) {
        super(props, context);
        this.sliderRef = createRef();
    }

    componentDidMount() {
        this.props.action(this.sliderRef)
    }


    render() {

        const initialSettings = {
            dots: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1190,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 925,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 445,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        };
        
        const allSettings = {
            ...initialSettings,
            ...this.props.settings
        }
        
        return (
            <div>
                <Slider id={this.props.sliderId}
                        {...allSettings} 
                        ref={this.sliderRef}
                        
                >
                    {this.props.children}
                </Slider>
            </div>
        );
    }
}


export default BookSlider