import React, {Component} from "react"
import Kitaptaslak from "../KitapTaslak/kitap-taslak"
import baseURL from "../../common"


class DosyaAramaSonucu extends Component {
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            dosyalarSonuc: [],
            ara: '',
        }
    }

    static getDerivedStateFromProps(props) {
        if (props.qAramaMod !== "dosyalar") {
            return {
                ara: ''
            }
        }
        else {
            return {
                ara: props.ara
            }
        }
    }

    componentDidMount() {
        const {ara} = this.state
        if (ara !== ''){
            this.dosyayiAra(ara)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ara !== this.props.ara) {
            this.dosyayiAra(this.props.ara)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    dosyayiAra(ara) {
        const url = baseURL + "Arama/Dosyalar?dosyaAd=" + ara;
        this._isMounted = true

        return fetch(url)
            .then((response) => response.json())
            .then((result) =>
                {
                    if (this._isMounted) {
                        this.setState({
                            dosyalarSonuc: result.data
                        })
                    }
                }
            );
    }

    render() {
        const {dosyalarSonuc} = this.state
        return (
            <div className="row justify-content-start">
                {dosyalarSonuc &&
                dosyalarSonuc.map((item) => (
                    <div key={item.dosyaId}
                         className="align-self-center col-xs-12 col-sm-6 col-md-4 col-lg-3 my-3">
                        <Kitaptaslak
                            dosyaId={item.dosyaId}
                            foto={item.foto}
                            baslik={item.baslik}
                            isim={item.isim}
                            detay={item.detay}
                            link="dosyalar/detay"
                        />
                    </div>
                ))}
            </div>
        )
    }
}

export default DosyaAramaSonucu
