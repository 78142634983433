import React from 'react'
 
function ArrowNext() {
  return (
    <svg 
    
    className=" icon arrow arrownext"
         width="24px" height="24px"
         viewBox="0 0 16 16">
    <g>
      <g>
        <path
          
          d="M11.5 7c-0.139 0-0.278-0.058-0.377-0.171l-3.124-3.57-3.124 3.57c-0.182 0.208-0.498 0.229-0.706 0.047s-0.229-0.498-0.047-0.706l3.5-4c0.095-0.108 0.232-0.171 0.376-0.171s0.281 0.062 0.376 0.171l3.5 4c0.182 0.208 0.161 0.524-0.047 0.706-0.095 0.083-0.212 0.124-0.329 0.124z"
        />
       
      </g>
    </g>
  </svg>
  )
}

export default ArrowNext;


 