import React, { Component } from 'react';

class Jumbotron extends Component {
	render() {
		//destruction
		const { height, arkaplanresmi, display, renk, resmigizle } = this.props;

		const { jumbobaslik, hakkimizda } = this.props;
		const { goster ,bottom} = this.props;
		const { eserFoto } = this.props;

		return (
			<div style={{ backgroundColor: renk }} className=" col-md-12 jumbotron-cerceve ">
				<div
					style={{ height: height, background: resmigizle }}
					className="  jumbotron mycontainer jumbotron-fluid"
				>
					<div className="Header-akademi" style={{ display: display }}>
						<div className="Header-akademi-resim"  >
							<img alt="" className="kavseynnnn" src={arkaplanresmi} />
						</div>
					</div>
					<div style={{ display: jumbobaslik,bottom:bottom }} className="jumbotron-baslik">
						<p className="  buyuk-baslik">{hakkimizda}</p>
					</div>

					<div style={{ display: goster }} className="   jumbotron-yazarkitap container-fluid  ">
						 <div className=" row baslikVeResim">
							 <div className="col-md-2 col-sm-2 jumbotron-yazarkitap-sol">
							 <img alt="" className="dosya-detay-resim" src={eserFoto} />
							</div>
							<div className=" col-md-4 col-sm-4 jumbotron-yazarkitap-sag">
								<div className="buyuk-baslik">{this.props.eserIsmi}</div>
								<div className="dosya-yazar-ismi">{this.props.yazar}</div>
							</div>
							<div className=" jumbotron-yazarkitap-orta col-md-6 col-sm-6  "></div>
						 </div>
							
						 
					</div>
				</div>
			</div>
		);
	}
}
export default Jumbotron;
