import baseURL from '../../common';

export function getAnasayfaAction(params) {
	return function(dispatch) {
		let url = baseURL + 'anasayfa';
		return fetch(url)
		.then((response) => response.json())
		.then((result) => dispatch(getAnasayfaSuccess(result)));
	};
 
}

export function getAnasayfaSuccess(kitaplar) {
	return {
		type: 'GET_ANASAYFA',
		payload: kitaplar.data
	};
}

//bu aksiyon anasayfada bulunan kategorilerin api bilgisini almak için oluşturuldu
//ayrıca içerikler dropdown  altındaki kategorilerin de içeriklerinin alınmasında kullanıldı. 