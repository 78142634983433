/*
import {Component} from "react";
import {withRouter} from "react-router";

class ScrollToTop extends Component {
    componentWillUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo({
                top:  0,
                left: 0,
                behavior: 'auto'
            });
        }
    }
    render() {
        return this.props.children;
    }
}
*/
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';


function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
}

export default withRouter(ScrollToTop);
