import React, {useState} from "react";
import baseURL from "@/common";
import {setToken} from "@/utils";


export default function LoginPage({history}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleClick = async (e) => {
        e.preventDefault();

        const url = baseURL + "Auth/Login";
        
        const data = {
            email,
            password
        }
        
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        
        let res = await response.json();
        const token = res["token"];
        if (token === undefined) {
            alert(res.message)
            return;
        }
        setToken(token)
        history.push('/')
    }
    
    return (
        <div className="login-scoped-root">
            <div className="wrapper fadeInDown">
                <div id="formContent">
                    <div className="fadeIn first">
                        <h3 className="my-3">Akademi <br/> Kabıkavseyn Giriş</h3>
                    </div>
                    <form>
                        <input value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               type="text" id="login" className="fadeIn second" name="login" placeholder="Kullanıcı Adı" />
                        <input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password" id="password" className="fadeIn third" name="login" placeholder="Şifre" />
                        <input onClick={handleClick}
                               type="submit" className="fadeIn fourth my-3" value="Giriş Yap" />
                    </form>
                </div>
            </div>
        </div>
        
    );
}
