import React, { Component } from 'react';
import Jumbotron from '../../../Components/Jumbotron/jumbotron';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import KitapTaslak from '../../../Components/KitapTaslak/kitap-taslak';
import { getAnasayfaAction } from '../../../Redux/Actions/anasayfaActions';

class TumAnsiklopedi extends Component {
	componentDidMount() {
		this.props.actions.getAnasayfaAction();
	}

	tumAnsiklopediler = () => {
		const { getAnsiklopediReducer } = this.props;
		if (getAnsiklopediReducer) {
			return (
				<div className="row">
					{getAnsiklopediReducer.ansiklopediler &&
						getAnsiklopediReducer.ansiklopediler.map((item) => (
							<KitapTaslak
								key={item.ansiklopediId}
								foto={item.foto}
								baslik={item.baslik}
								yazar={item.yazar}
								dosyaId={item.ansiklopediId}
								link="ansiklopediler"
							/>
						))}
				</div>
			);
		} else {
			return <div>Ansiklopedi Bulunamadi...</div>;
		}
	};

	render() {
		const display = 'block';
		const faaliyetler = 'Tüm Ansiklopediler';
		return (
			<div>
				<Jumbotron hakkimizda={faaliyetler} jumbobaslik={display} />

				<div className="TumAnsiklopediler mycontainer  MarginDegeri  ">{this.tumAnsiklopediler()}</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		getAnsiklopediReducer: state.getAnasayfaReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getAnasayfaAction: bindActionCreators(getAnasayfaAction, dispatch)
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TumAnsiklopedi);
