export default function icerikReducer(state = {}, action) {
	switch (action.type) {
		case 'GET_KITAPLAR_ICERIK_BYID':
			return action.payload;
		
		default:
			// debugger;
			return state;
	}
}


