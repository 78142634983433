import React, { Component } from 'react'
import Select from 'react-select';


const options = [
    { value: '-', label: 'Tüm Lügatlar' },
    { value: 'Arapça Exe', label: 'Arapça Exe' },
    { value: 'Arapça Farsça Exe', label: 'Arapça Farsça Exe' },
    { value: 'ARAPÇA ISTILAHLARI', label: 'Arapça Istılahları' },
    { value: 'arapça kelimeler', label: 'Arapça Kelimeler' },
    { value: 'Arapça Türkçe Sözlük (tevakku)', label: 'Arapça Türkçe Sözlük (Tevakku)' },
    { value: 'Aslı ve Günümüz Türkçeyle Mecelle', label: 'Aslı ve Günümüz Türkçeyle Mecelle' },
    { value: 'BELAGAT VE EDEBİ SANATLAR', label: 'Belagat ve Edebi Sanatlar' },
    { value: 'Dini Kavramlar Sözlüğü', label: 'Dini Kavramlar Sözlüğü' },
    { value: 'Diyanet Hadis Terimleri', label: 'Diyanet Hadis Terimleri' },
    { value: 'el-isabe', label: 'el-İsabe' },
    { value: 'Farisiler Exe', label: 'Farisiler Exe' },
    { value: 'Fetâvâ-i Hindiyye', label: 'Fetâvâ-i Hindiyye' },
    { value: 'Fıkıh ve Hukuk Lugatı', label: 'Fıkıh ve Hukuk Lugatı' },
    { value: 'Grup: Hadis Terimleri', label: 'Grup: Hadis Terimleri' },
    { value: 'Hadis Terimleri', label: 'Hadis Terimleri' },
    { value: 'İhya Hadis Sözlüğü', label: 'İhya Hadis Sözlüğü' },
    { value: 'İsam', label: 'İsam' },
    { value: 'İsim Yazılışları', label: 'İsim Yazılışları' },
    { value: 'Kamus', label: 'Kamus' },
    { value: 'Kamus-u Türki', label: 'Kamus-u Türki' },
    { value: 'Kontrollu', label: 'Kontrollu' },
    { value: 'Kubbe Altı', label: 'Kubbe Altı' },
    { value: 'Kuranda Geçen Exe', label: 'Kuranda Geçen Exe' },
    { value: 'Lugat-ı Remzi', label: 'Lugat-ı Remzi' },
    { value: 'Luggat', label: 'Luggat' },
    { value: 'Mantık Terimleri Sözlüğü', label: 'Mantık Terimleri Sözlüğü' },
    { value: 'Mecelle Istılahları', label: 'Mecelle Istılahları' },
    { value: 'Osmanlıca Lugat Exe', label: 'Osmanlıca Lugat Exe' },
    { value: 'Osmanlıca Yazılışları', label: 'Osmanlıca Yazılışları' },
    { value: 'tabakat', label: 'Tabakat' },
    { value: 'TEFSİR TERİMLERİ SÖZLÜĞÜ', label: 'Tefsir Terimleri Sözlüğü' },
    { value: 'TERİMLER VE KAVRAMLAR SÖZLÜĞÜ', label: 'Terimler ve Kavramlar Sözlüğü' },
    { value: 'Vankulu', label: 'Vankulu' },
    { value: 'www.ebuzziya.com', label: 'www.ebuzziya.com' },
    { value: 'www.ehlisunnetbuyukleri.com', label: 'www.ehlisunnetbuyukleri.com' },
    { value: 'www.kamusiosmani.net', label: 'www.kamusiosmani.net' },
    { value: 'www.lehceiosmani.com', label: 'www.lehceiosmani.com' },
    { value: 'www.lugatinaci.com', label: 'www.lugatinaci.com' },
    { value: 'www.osmanlilugati.com', label: 'www.osmanlilugati.com' },
    { value: 'www.resimlikamus.com', label: 'www.resimlikamus.com' },
    { value: 'www.turkcekamus.com', label: 'www.turkcekamus.com' },
    { value: 'www.turkcelugat.com', label: 'www.turkcelugat.com' },
];

 //search ikonuna tıklandığında ilk basta görünmeyen ama tüm kategoriler dışında seçim yapıldıgında görünür olan ikinci dropdown dur
class SecondDropdown extends  Component {

  state = {
    selectedOption: options[0],
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption.value)
    console.log(`Option selected:`, selectedOption.value);
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
          style={{width: '100%;'}}
        className={'customSelect'}
        value={selectedOption}
          autosize={false}
          clearable={true}
        onChange={this.handleChange}
        options={options}
        isSearchable={false}
        closeMenuOnSelect={true}
        styles={{
          dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
          }),
          control: (base) => ({
            // none of react-select's styles are passed to <Control />
            ...base,

          }),
        }}
      />
    );
  }
}

export default SecondDropdown
