import React, { Component } from 'react';
import Buttonuzun from '../../Components/Buttonuzun/buttonuzun';
class Error extends Component {
	render() {
		const gonder = 'AnaSayfaya Dön';
		const color = 'whitesmoke';
		const link = '/';
		return (
			<div className="ErrorKismi   ">
				<div className="container-fluid ">
					<div className="jumbotron ">
						<div className="bulunmadi-404">404</div>
						<div className="bulunmadi-404-uyari">Aradığınız Sayfa Bulunamadı</div>
						<div>
							<Buttonuzun link={link} gonder={gonder} color={color} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Error;
