import React, { Component } from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import KitapTaslak from '../../Components/KitapTaslak/kitap-taslak';
import { getAnsiklopedilerAction } from '../../Redux/Actions/ansiklopediActions';
class AnsiklopedilerPage extends Component {
	//bir ansikloepdi birden fazla kitabı barındırır. anasayfa da ansiklopedi ye tıklanınca
	//içerisinde kaç kitab bulunduğunu gösteren sayfamızdır
	componentDidMount = () => {
		let { match } = this.props; //url den ansiklopedi id sini alıp ona göre veriyi redux dan cekme
		const slug = match.params.slug;

		this.props.actions.getAnsiklopediler(slug); //id si alınan ansiklopedinin action fonksiyonunu aktif etme
	};

	seciliAnsiklopedi = () => {
		const { getAnsiklopedilerReducer } = this.props;
		if (getAnsiklopedilerReducer) {
			return (
				<div className="ansiklopedi row">

					{getAnsiklopedilerReducer.kitaplar &&
						getAnsiklopedilerReducer.kitaplar.map((item) => (
							<div key={item.kitapId} className="col-xs-6 col-sm-6 col-md-4 col-lg-3 my-3">
								<KitapTaslak
									dosyaId={item.kitapId}
									tur={item.tur}
									foto={item.foto}
									baslik={item.baslik}
									link="/ansiklopediler/kitapdetaylari"
								/>
							</div>
						))}
				</div>
			);
		} else {
			return <div>Ansiklopedi Bulunamadi...</div>;
		}
	};

	render() {
		const display = 'block';
		const faaliyetler = 'Ansiklopediler';
		return (
			<div>
				<Jumbotron hakkimizda={faaliyetler} jumbobaslik={display} />
				<div className=" mycontainer  MarginDegeri AnsiklopedilerPage">{this.seciliAnsiklopedi()}</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		getAnsiklopedilerReducer: state.getAnsiklopedilerReducer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			getAnsiklopediler: bindActionCreators(getAnsiklopedilerAction, dispatch)
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AnsiklopedilerPage);
