//tıklanan ansiklopediye ait kaç kitap var ise bul ve getir
import baseURL from "../../common";

export function getKitaplarSuccess(result) {
    return { type: 'GET_KITAPLAR_BYNAME', payload: result };
}

export function getKitaplarAction(slug, pageIndex, filter) {
    return function(dispatch) {
        let url = baseURL + 'kitap';
        url = url + '/' + 'GetKitapByName?slug=' + slug + "&PageNumber=" + pageIndex + "&PageSize=200";
        
        if (filter) {
            url += "&filter="+filter
        }
        
        return fetch(url).then((response) => response.json()).then((result) => dispatch(getKitaplarSuccess(result)));
    };
}