import React, {Component} from 'react'

import Jumbotron from '../../../Components/Jumbotron/jumbotron';
import {bindActionCreators} from "redux";
import {getAnasayfaAction} from "../../../Redux/Actions/anasayfaActions";
import {connect} from "react-redux";
import {getKitaplarAction} from "../../../Redux/Actions/kitapActions";
import KitapTaslak from "../../../Components/KitapTaslak/kitap-taslak";

class Kitap extends Component {

    componentDidMount() {
        this.props.actions.getKitaplarAction();
    }
    
    render() {
        const display = 'block';
        const hakkimizda = 'Kitaplar';

        return (
            <div>
                <Jumbotron hakkimizda={hakkimizda} jumbobaslik={display}/>
                <div className="LugatlarKismi mycontainer MarginDegeri">
                    {this.tumKitaplar()}
                </div>
            </div>
        )
    }
    
    tumKitaplar = () => {
        const { getKitapReducer } = this.props;
        
        if ('length' in getKitapReducer) {
            return (
                <div className="row">
                    {getKitapReducer.map(item => (
                        <KitapTaslak
                            key={item.kitapId}
                            dosyaId={item.kitapId}
                            foto={item.foto}
                            baslik={item.baslik}
                            link="/kitaplar/kitapdetaylari"
                        />
                    ))}
                </div>
            );
        } else {
            return <div>Kitap Bulunamadi...</div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        //reducer a baglanma
        getKitapReducer: state.getKitapReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            //aksiyon a baglanma
            getKitaplarAction: bindActionCreators(getKitaplarAction, dispatch)
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Kitap);