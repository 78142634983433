import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";

import './custom.css'

class AramaSonucu extends Component {


	kubbeAltiGoster = () => {
		this.props.onClick(true)
	}

	kubbeAltiGizle = () => {
		this.props.onClick(false)
	}


	render() {
		const { baslik, mana, aradiLugat } = this.props;

		return (
			<div className="AramaSonuc">
				<div className="mycontainer">
					<div className="row border-danger ">
						<div className="col-md-12">
							<div className="row pt-2 pb-2">
								<div className="col-md-6 kubbealtiLeft">{baslik}</div>
									{aradiLugat === '-' ? (
										<div className="col-md-6 kubbealtiRight d-flex row no-gutters">
											<div className="col-md-7 col-sm-7 kubbeAltiShowHide">
												<a style={{cursor: 'pointer'}} onClick={this.kubbeAltiGoster}>Sadece Kubbe Altı Göster</a>
											</div>

											<div className="col-md-5 col-sm-5 kubbeAltiShowHide">
												<a style={{cursor: 'pointer'}} onClick={this.kubbeAltiGizle}>Kubbe Altı Gizle</a>
											</div>
										</div>
									) : null}
								<div className="col-md-12 pt-3 paragraf">
									{ReactHtmlParser (mana )}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default AramaSonucu;

/*
{
										transform: (node, index) => {
											if (node.type === 'tag' && node.name === 'span'){
												return null
											}
										}
									}
 */
