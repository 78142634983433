import React, { Component } from 'react';
import Select from 'react-select';

const options = [
	{ value: 'Dosyalar', label: 'Dosyalar', kod: 1 },
	{ value: 'Biyografiler', label: 'Biyografiler', kod: 2 },
	{ value: 'Lugatlar', label: 'Lügatlar', kod: 3 },
	{ value: 'Ansiklopediler', label: 'Ansiklopediler', kod: 4 }
];

class FirstDropdown extends Component {
	state = {
		selectedOption: options.label,
	};
	render() {
		const { selectedOption } = this.state;
		return (
			<Select
				className="first"
				value={selectedOption}
				placeholder={'Seçiniz...'}
				onChange={this.props.onChange}
				options={options}
        		closeMenuOnSelect={true}
				isSearchable={false}
				styles={{
					container: (base, state) => ({
						...base,
						zIndex: "999"
					}),
					dropdownIndicator: (base, state) => ({
						...base,
						transition: 'all .2s ease',
						transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
					}),
					menu: (base, state) => ({
						...base,
						//position: 'initial'
					})
				}}
			/>
		);
	}
}

export default FirstDropdown;
