//kitabın index ine tıklandıgında index(kitap başlıklar) in içeriğini getirsin

import baseURL from "../../common";
import kitap from "../../Pages/icerikDropdown/tumKitap/kitap";

export function getKitaplarIcerikSuccess(result) {
	return { type: 'GET_KITAPLAR_ICERIK_BYID', payload: result.data };
}

export function getKitaplarIcerikAction(kitapIcerikId) {
	return function(dispatch) {
		let url = baseURL + 'Icerik';
		if (kitapIcerikId) {
			// debugger;
			url = url + '/' + kitapIcerikId;
		}
		return fetch(url)
			.then((response) => response.json())
			.then((result) => dispatch(getKitaplarIcerikSuccess(result)));
	};
}



