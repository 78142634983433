import baseURL from '../../common';

export function getAnsiklopedilerSuccess(result) {
	// debugger
	return { type: 'GET_ANSIKLOPEDI_BYID', payload: result.data };
}


export function getAnsiklopedilerAction(slug) {
	return function(dispatch) {
		let url = baseURL + 'ansiklopedi/GetAnsiklopediByName?slug=' + slug;
		
		return fetch(url)
			.then((response) => response.json())
			.then((result) => dispatch(getAnsiklopedilerSuccess(result)));
	};
}
//ansiklopediler sayfasındaki ansiklopedileri almak için oluşturuldu