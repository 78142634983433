export default function kitapReducer(state = {}, action) {
	switch (action.type) {
		case 'GET_KITAPLAR_BYNAME':
			return action.payload;
		default:
			// debugger;
			return state;
	}
}



