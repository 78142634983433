import React, { Component } from 'react';
import Jumbotron from '../../Components/Jumbotron/jumbotron';
import Icon from '../../Components/Iconlar';
// import Buttonuzun from '../../Components/Buttonuzun/buttonuzun';
class Iletisim extends Component {
	componentDidMount = () => {};

	render() {
		const color = 'whitesmoke';
		const gonder = 'Gönder';
		// const width = '100%';
		const display = 'block';
		const faaliyetler = 'İletişim';

		return (
			<div>
				<Jumbotron hakkimizda={faaliyetler} jumbobaslik={display} />

				<div className="IletisimKismi MarginDegeri">
					<div className="mycontainer">
						<div className="row">
							<div className=" col-sm-12 col-md-4 ">
								<div className="IletisimKismi-bolumler-solbolum">
									<p className="kucuk-baslik">İletişim Bilgileri</p>
									<div>
										<p className="paragraf">Telefon Numarası</p>
										<p className="paragraf  paragraf-renk">0 212 456 78 90</p>
									</div>
									<div>
										<p className="paragraf">Adres:</p>
										<p className="paragraf  paragraf-renk">
											Lorem ipsum mah. Kavs cad. No 33 Maltepe / İstanbul
										</p>
									</div>
									<div>
										<p className="paragraf">Mail Adresi:</p>
										<p className="paragraf  paragraf-renk">info@kavseynakademi.com</p>
									</div>
								</div>
							</div>
							<div className=" col-sm-12 col-md-8  row">
								<div className="IletisimKismi-bolumler-sagbolum      ">
									<p className="kucuk-baslik row  ">İletişim Formu</p>

									<div className="iletisim-gonder col ">
										<form>
											<div className="row">
												<div className="form-group col-md-4">
													<input
													autoComplete="off"
														type="text"
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Adınız Soyadınız"
													/>
												</div>

												<div className="form-group col-md-4">
													<input
													autoComplete="off"
														type="email"
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="E-mail@kavseyn.com"
														data-required-message="E-Mail or Username is Required!"
													/>
												</div>
												<div className="form-group col-md-4">
													<input
													autoComplete="off"
														type="email"
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
														placeholder="Konu"

													/>
												</div>
											</div>

											<div className="form-group">
												<label htmlFor="exampleFormControlTextarea1" />
												<textarea
													className="form-control"
													id="exampleFormControlTextarea1"
													rows="5"
												/>
											</div>

											<button
												type="submit"
												className="button-oval btn-gonder "
												style={{ color: color }}
											>
												{gonder}
												<Icon name="arrow-next" />
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Iletisim;
